import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import {
  FieldWrapper,
  LabelWrapper,
  OptionalLabel,
  RequiredFieldWrapper,
  StyledLabel,
  StyledTextInput,
} from '../TextInput/TextInputPanel';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/ColorPicker/ColorPicker';
import { Checkbox, FormControl } from '@uitk/react';
import styled from 'styled-components';
import { ColorButtonWrapper } from '../Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';

export const ColorLabelStyles = {
  color: '#1d1929',
  'font-size': '14px',
  'font-family': 'OptumSans',
  'padding-top': '4px',
  'font-weight': '400',
};

const ColorButtonAndLabelWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
`;

export interface DropdownPanelProps<T> {
  pages: Array<Page>;
  site: Site;
  widgetState: Widget<T>;
  updateSite: (site: Site) => void;
  saveSite: () => void;
  handleFieldRequirement: (data: boolean) => void;
  handleFieldLabel: (data: string) => void;
  handleSelectValues: (data: string) => void;
  handleIconColor: (data: string) => void;
}

const DropdownPanel = (props: DropdownPanelProps<any>) => {
  const {
    handleFieldRequirement,
    handleFieldLabel,
    handleSelectValues,
    handleIconColor,
    widgetState,
    site,
    updateSite,
    saveSite,
  } = props;
  const [required, setRequired] = useState(widgetState.config.required);
  const [label, setLabel] = useState(widgetState.config.label);
  const [selectValues, setSelectValues] = useState(widgetState.config.value);
  const [tempButtonColor, setTempButtonColor] = useState(
    widgetState.config.buttonColor
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  const buttonTypeText = () => {
    if (widgetState.type === 'Dropdown') {
      return 'Dropdown Icon Color';
    } else {
      return 'Button Color';
    }
  };

  useEffect(() => {
    setRequired(widgetState.config.required);
    setLabel(widgetState.config.label);
    setSelectValues(widgetState.config.value);
    setTempButtonColor(widgetState.config.buttonColor);
  }, [widgetState.config]);

  useEffect(() => {
    handleIconColor(tempButtonColor);
  }, [tempButtonColor]);

  const handleRequired = (e: ChangeEvent<HTMLInputElement>) => {
    setRequired(e.target.checked);
    handleFieldRequirement(e.target.checked);
  };

  const handleLabel = (e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
    handleFieldLabel(e.target.value);
  };

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectValues(e.target.value);
    handleSelectValues(e.target.value);
  };

  const handleColorEditClick = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
      toast('Gradient cannot be used for icon color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      setTempButtonColor(newColor);
    }
  };

  return (
    <>
      <FieldWrapper>
        <LabelWrapper>
          <StyledLabel>Label</StyledLabel>
          <OptionalLabel>(Optional)</OptionalLabel>
        </LabelWrapper>
        <StyledTextInput
          data-test-id="dropdown-label-input"
          value={label}
          onChange={handleLabel}
        />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <StyledLabel>Values</StyledLabel>
          <OptionalLabel>(Add comma-separated values)</OptionalLabel>
        </LabelWrapper>
        <StyledTextInput
          data-test-id="dropdown-values-input"
          value={selectValues}
          onChange={handleSelect}
        />
      </FieldWrapper>
      <ColorButtonAndLabelWrapper>
        <StyledLabel style={ColorLabelStyles}>{buttonTypeText()}</StyledLabel>
        <ColorButtonWrapper
          data-test-id="color-icon"
          id="button-color"
          onClick={handleColorEditClick}
        >
          <ColorButton color={tempButtonColor} />
        </ColorButtonWrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            site={site}
            value={tempButtonColor}
            handleColorChange={handleColorChange}
            onChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          />
        </StyledPickerPopover>
      </ColorButtonAndLabelWrapper>
      <RequiredFieldWrapper>
        <StyledLabel>This field is required</StyledLabel>
        <FormControl id="checkbox-form-control">
          <Checkbox
            data-test-id="required-checkbox"
            checked={required}
            onChange={handleRequired}
          />
        </FormControl>
      </RequiredFieldWrapper>
    </>
  );
};

export default DropdownPanel;
