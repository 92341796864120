import SectionWidget from './SectionWidget';
import { Config } from './types';
import SectionWidgetControl from './SectionWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultSizeLocationData,
  defaultBoxShadowData,
} from 'builder/util/constants';

export default {
  type: 'Section',
  displayName: 'Section',
  icon: 'pipe-section',
  initialConfig: {
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultSizeLocationData,
    ...defaultBorderData,
    ...defaultBoxShadowData,
    uniqueId: 'section345',
    enableFullScreen: false,
    visible: true,
  },
  components: {
    render: SectionWidget,
    control: SectionWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;

export { SectionWidget };
