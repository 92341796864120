import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledImageWidget = styled.div``;

const ImageWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    backgroundImage,
    paddingData,
    borderData,
    lineColor,
    altText,
    height,
    visible,
    isCustomWidget,
  } = props.widgetState.config;

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledImageWidget
      style={{
        marginTop: '0',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: lineColor,
        borderRadius: `${borderData?.radius}px`,
        height: height,
        width: '100%',
      }}
    >
      {backgroundImage?.url ? (
        <img
          style={{ height: '100%', width: '100%' }}
          src={backgroundImage?.url}
          draggable={false}
          alt={altText}
        />
      ) : (
        <img
          style={{ height: '100%', width: '100%' }}
          src={
            'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png'
          }
          alt=""
          draggable={false}
        />
      )}
    </StyledImageWidget>
  );
};
export default ImageWidget;
