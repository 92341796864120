import ImageWidget from './ImageWidget';
import { Config } from './types';
import ImageWidgetControl from './ImageWidgetControl';
import {
  defaultBackgroundImageData,
  defaultBorderData,
  defaultPaddingData,
  defaultSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'Image',
  displayName: 'Image',
  icon: 'Image',
  initialConfig: {
    ...defaultBackgroundImageData,
    ...defaultSizeLocationData,
    ...defaultPaddingData,
    ...defaultBorderData,
    uniqueId: '123abc',
    lineColor: 'red',
    enableFullScreen: false,
    visible: true,
  },
  components: {
    render: ImageWidget,
    control: ImageWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
