import { Panel } from '@uitk/react';
import { widgetTypes } from 'builder/util/constants';
import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { Config } from './types';

const { AccordionGroup, Group } = Panel;

const StyledSectionWidget = styled.div``;
const StyledPanel = styled(Panel)<{
  hoverFocusColor: string;
  textColor: string;
  fontFamily: string;
  fontSize: number;
}>`
  & button {
    border-left: 4px solid ${p => p.textColor};
  }
  & button[aria-expanded='true'] {
    border-left: 4px solid ${p => p.textColor} !important;
  }
  & button span {
    color: ${p => p.textColor} !important;
    font-family: ${p => p.fontFamily} !important;
    font-size: ${p => p.fontSize}pt !important;
  }
  & button > span > span > svg {
    fill: ${p => p.textColor} !important;
  }
  & button:after {
    box-shadow: 0 4px 0 0 ${p => p.hoverFocusColor} !important;
  }
  & button:focus,
  & button:hover {
    cursor: default;
    box-shadow: 0 0 0 3px ${p => p.hoverFocusColor} !important;
  }
`;

const StyledAccordionGroup = styled(AccordionGroup)`
  border-bottom: none;
`;

const AccordionWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const { currentPage, value, onChange } = props;
  const { id: activeWidgetId, defaultWidget } = props.widgetState;
  const {
    accordionPanels,
    currentTabIndex,
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
    accordionConnected,
    hoverFocusColor,
    textColor,
    font,
    fontSize,
    visible,
    isCustomWidget,
  } = props.widgetState.config;

  const handlePanelClick = (panelId: number) => {
    if (panelId === null) {
      props.closeAccordionPanel(activeWidgetId, 0);
    } else {
      props.openAccordionPanel(activeWidgetId, getMaxYLocation(panelId));
    }

    const accordionChildren = currentPage.content[activeWidgetId].children;
    accordionChildren.forEach(child => {
      const childWidget = document.getElementById(child);
      const isVisible = currentPage.content[child].tabIndex === panelId;
      if (currentPage.content[child].config.visible !== isVisible) {
        props.changeWidgetVisible(
          props.siteId,
          props.currentPage.pageId,
          activeWidgetId,
          child,
          isVisible,
          panelId
        );
      }
      if (childWidget) {
        // Todo: This z-index setting creates a bug and the image in a card is not shown when the card is a child of an accordion
        // if (currentPage.content[child].type !== widgetTypes.Image) {
        if (currentPage.content[child].type === widgetTypes.Card) {
          childWidget.style.zIndex = '1';
        } else {
          childWidget.style.zIndex = '2';
        }
        // }
      }
    });

    onChange(
      {
        ...value,
        currentTabIndex: panelId,
      },
      activeWidgetId,
      defaultWidget
    );
  };

  // Increment 50 per each accordion panel to the total accordionPanels length
  const getIncrementalPanelHeight = (panelLength: number): number => {
    let height = 50;
    for (let i = 0; i < panelLength; i++) {
      height += 50;
    }
    return height;
  };

  const getMaxYLocation = (panelId?: number): number => {
    const { currentPage } = props;
    const panelIndex = panelId !== undefined ? panelId : currentTabIndex;
    let maxY = 120,
      yLocation;
    const parentYLocation =
      currentPage.content[activeWidgetId].config.yLocation;
    Object.values(currentPage.content[activeWidgetId].children).map(
      childWidgetId => {
        if (currentPage.content[childWidgetId]?.tabIndex === panelIndex) {
          yLocation =
            currentPage.content[childWidgetId].config.yLocation -
            parentYLocation +
            currentPage.content[childWidgetId].config.height -
            getIncrementalPanelHeight(panelIndex);
        }
        if (yLocation > maxY) maxY = yLocation;
      }
    );
    return maxY;
  };

  let PanelGroupComponent;
  if (accordionConnected) {
    PanelGroupComponent = StyledAccordionGroup;
  } else {
    PanelGroupComponent = Group;
  }

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <PanelGroupComponent
      className="PanelGroup"
      data-test-id="grouped-panel"
      onHeaderPress={handlePanelClick}
      open={currentTabIndex}
    >
      {accordionPanels.map((panel, index) => (
        <StyledPanel
          key={index}
          title={panel.name}
          className={`panel-${index}`}
          hoverFocusColor={hoverFocusColor}
          textColor={textColor}
          fontFamily={font}
          fontSize={fontSize}
        >
          <StyledSectionWidget
            id={'sectionWidget'}
            className={'sectionWidget'}
            style={{
              backgroundColor:
                backgroundData?.color.startsWith('#') ||
                backgroundData?.color.startsWith('rgb')
                  ? backgroundData?.color
                  : '',
              backgroundImage:
                backgroundData?.style === 'Image'
                  ? `url('${backgroundImage?.url}')`
                  : backgroundData?.style === 'Color' &&
                    !(
                      backgroundData?.color.startsWith('#') ||
                      backgroundData?.color.startsWith('rgb')
                    )
                  ? backgroundData?.color
                  : '',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderWidth: `${borderData?.thickness}px`,
              borderStyle: 'solid',
              borderColor: `${lineColor}`,
              borderRadius: `${borderData?.radius}px`,
              backgroundClip: 'content-box',
              width: '100%',
              height: getMaxYLocation(),
              // borderBottom: '1px solid #4B4D4F',
            }}
          />
        </StyledPanel>
      ))}
    </PanelGroupComponent>
  );
};
export default AccordionWidget;
