// @ts-nocheck
import { Config } from './types';
import { defaultMarkdownData } from 'builder/util/constants';
import MarkdownWidget from './MarkdownWidget';
import MarkdownWidgetControl from './MarkdownWidgetControl';

export default {
  type: 'Markdown',
  displayName: 'Markdown',
  initialConfig: {
    ...defaultMarkdownData,
    uniqueId: '123abc',
    width: 600,
    height: 'auto',
    xLocation: 10,
    yLocation: 10,
    visible: true,
  },
  components: {
    render: MarkdownWidget,
    control: MarkdownWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
