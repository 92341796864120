import {
  BLANK_TEMPLATE_NAME,
  FORM_TEMPLATE_NAME,
  GENERAL_TEMPLATE_NAME,
} from 'builder/util/constants';

const templates: Array<Preset> = [
  {
    // default initialValue.content in CreatePageDialog
    name: BLANK_TEMPLATE_NAME,
    icon: undefined,
    widgets: {
      root: {
        id: 'root',
        type: 'List',
        config: {
          bgcolor: 'transparent',
        },
        children: [
          'footerText1',
          'footerText2',
          'footerDivider',
          'headerLogoText',
          'headerText',
          'headerSection',
          'footerSection',
          'footerImage',
          'footerLink1',
          'footerLink2',
          'footerLink3',
          'loginButton',
          'navBar',
        ],
        permanent: true,
        defaultWidget: true,
      },
      headerSection: {
        id: 'headerSection',
        type: 'Section',
        defaultWidget: true,
        config: {
          visible: true,
          enableFullScreen: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#FBF9F4',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 4,
            blurRadius: 5,
            spreadRadius: 0,
          },
          boxShadowColor: 'lightgrey',
          width: 1275,
          height: 102,
          xLocation: 0,
          yLocation: -1,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: ['headerLogoText', 'headerText', 'loginButton', 'navBar'],
        tabIndex: -1,
      },
      headerLogoText: {
        id: 'headerLogoText',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Optum',
          align: 'left',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 18,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#FF612B',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 93,
          height: 34,
          xLocation: 31,
          yLocation: 15,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'black',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      headerText: {
        id: 'headerText',
        type: 'Text',
        config: {
          visible: true,
          align: 'left',
          backgroundData: { style: 'None', color: 'transparent' },
          backgroundImage: { url: '', alt: '' },
          bold: true,
          borderData: { thickness: 0, radius: 0 },
          centerAlign: false,
          characterSpacing: 0,
          font: 'OptumSans',
          fontFamily: 'OptumSans',
          fontSize: 14,
          height: 29,
          iconBackgroundColor: '#d9f6fa',
          isHorizonWidget: true,
          italic: false,
          leftAlign: false,
          lineColor: 'black',
          lineSpacing: 1,
          marginTop: 0,
          minHeight: 10,
          minWidth: 10,
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          rightAlign: false,
          spacing: { top: '', right: '', bottom: '', left: '' },
          strikeThrough: false,
          text: 'Optum Insight Developer',
          textColor: '#0C55B8',
          textHighlightColor: '',
          toolTip: '',
          underline: false,
          uniqueId: 'headerText',
          width: 249,
          xLocation: 31,
          yLocation: 51,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerSection: {
        id: 'footerSection',
        type: 'Section',
        defaultWidget: true,
        config: {
          visible: true,
          enableFullScreen: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#FBF9F4',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 4,
            blurRadius: 5,
            spreadRadius: 0,
          },
          boxShadowColor: 'lightgrey',
          width: 1275,
          height: 231,
          xLocation: 0,
          yLocation: 830,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: [
          'footerImage',
          'footerLink1',
          'footerDivider',
          'footerText2',
          'footerLink2',
          'footerLink3',
          'footerText1',
        ],
        tabIndex: -1,
      },
      footerImage: {
        id: 'footerImage',
        type: 'Image',
        defaultWidget: true,
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/529/OptumLogo1.png',
            alt: 'OptumLogo1.png',
          },
          width: 83,
          height: 35,
          xLocation: 31,
          yLocation: 868,
          minWidth: 10,
          minHeight: 10,
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'black',
          enableFullScreen: false,
          marginTop: 0,
          parentId: 'footerSection',
        },
        children: [],
        tabIndex: -1,
      },
      footerLink1: {
        id: 'footerLink1',
        type: 'Link',
        defaultWidget: true,
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link1',
          url: '',
          newTab: false,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '123abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 207,
          yLocation: 867,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          currentTab: 0,
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '1abc',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: 'Link1',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        children: [],
        tabIndex: -1,
      },
      footerDivider: {
        id: 'footerDivider',
        type: 'Divider',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          width: 1,
          height: 169,
          minWidth: 1,
          minHeight: 1,
          xLocation: 158,
          yLocation: 865,
          uniqueId: '123abc',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          direction: 'Vertical',
          thickness: '10',
          lineColor: '#9D9F9F',
          enableFullScreen: false,
          parentId: 'footerSection',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerText2: {
        id: 'footerText2',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: '© 2024 All rights reserved',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 101,
          height: 80,
          xLocation: 31,
          yLocation: 952,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: 'footerSection',
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerLink2: {
        id: 'footerLink2',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link2',
          currentTab: 0,
          url: '',
          newTab: true,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '2abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 310,
          yLocation: 867,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '2abc',
            borderData: {
              thickness: 0,
              radius: 0,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: '',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        defaultWidget: true,
        children: [],
        tabIndex: -1,
      },
      footerLink3: {
        id: 'footerLink3',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link3',
          url: '',
          newTab: true,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '3abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 412,
          yLocation: 867,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          currentTab: 0,
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '3abc',
            borderData: {
              thickness: 0,
              radius: 0,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: 'Link3',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        defaultWidget: true,
        children: [],
        tabIndex: -1,
      },
      footerText1: {
        id: 'footerText1',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'This is placeholder text. This is where you should place your legal text and/or privacy notice. It is recommended that you consult with your legal advisors for text that is relevant and adequate for your context and use. UI Toolkit cannot offer advice on legal text or privacy notices, this is outside our remit as a design system. Thank you for using UI Toolkit.',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 1040,
          height: 80,
          xLocation: 207,
          yLocation: 949,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: 'footerSection',
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      loginButton: {
        id: 'loginButton',
        type: 'LoginButton',
        copyable: false,
        defaultWidget: true,
        config: {
          visible: true,
          backgroundImage: { url: '', alt: '' },
          backgroundData: { style: 'None', color: 'transparent' },
          borderData: { thickness: 0, radius: 0 },
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          uniqueId: 'loginButton',
          width: 150,
          height: 75,
          xLocation: 1094,
          yLocation: 12,
          minWidth: 20,
          minHeight: 10,
          signInOptionEnabled: 'notEnabled',
          signInOptionTitle: 'Sign In Options',
          signInLinkColor: 'black',
          signInOptionLinks: [],
          lineColor: 'black',
          signInType: 'button',
          signInIcon: 'MoreVertical',
          fontSize: 12,
          font: 'OptumSans',
          buttonColor: '#c0e9ff',
          bold: false,
          italic: false,
          underline: false,
          strikeThrough: false,
          simulateLoggedOffUser: true,
          isHorizonWidget: true,
          parentId: 'headerSection',
        },
        children: [],
        tabIndex: -1,
      },
      navBar: {
        id: 'navBar',
        type: 'Navigation',
        defaultWidget: true,
        config: {
          visible: true,
          backgroundData: { style: 'Color', color: '#FBF9F4' },
          backgroundImage: { url: '', alt: '' },
          borderData: { radius: 0, thickness: 0 },
          dividerLineColor: '#DADBDC',
          enableFullScreen: false,
          font: 'OptumSans',
          fontSize: 12,
          height: 75,
          width: 129,
          xLocation: 965,
          yLocation: 12,
          minHeight: 30,
          minWidth: 40,
          iconColor: 'black',
          isHorizonWidget: true,
          lineColor: 'black',
          menuItems: [
            {
              displayText: 'Home',
              icon: { alt: '', url: '' },
              id: 1,
              isSection: false,
              pageSelected: null,
              tooltip: '',
              type: 'page',
              parentSectionId: null,
              url: '/home',
              visible: true,
            },
          ],
          showDividerLines: true,
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          style: 'Horizontal',
          textColor: 'black',
          treeOrder: [1],
          parentId: 'headerSection',
        },
        children: [],
        tabIndex: -1,
      },
    },
  },
  {
    name: FORM_TEMPLATE_NAME,
    icon: undefined,
    widgets: {
      root: {
        id: 'root',
        type: 'List',
        config: { visible: true, bgcolor: 'transparent' },
        children: [
          'template1Form',
          'Template1Text1',
          'Template1Text2',
          'Template1Section',
          'Template1Text3',
          'Template1Text4',
          'Template1Text5',
          'Template1Image',
          'Template1SectionText1',
          'Template1SectionText2',
          'Template1SectionButton',
          'Template1SectionTextInput1',
          'Template1SectionTextInput2',
          'Template1SectionTextInput3',
          'footerText1',
          'footerText2',
          'footerDivider',
          'headerSection',
          'headerText',
          'headerLogoText',
          'footerSection',
          'footerImage',
          'footerLink1',
          'footerLink2',
          'footerLink3',
          'Template1Button',
          'navBar',
          'loginButton',
        ],
        permanent: true,
        defaultWidget: true,
      },
      headerSection: {
        id: 'headerSection',
        type: 'Section',
        defaultWidget: true,
        config: {
          visible: true,
          enableFullScreen: false,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#FBF9F4',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 4,
            blurRadius: 5,
            spreadRadius: 0,
          },
          boxShadowColor: 'lightgrey',
          width: 1275,
          height: 102,
          xLocation: 0,
          yLocation: -1,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: ['headerLogoText', 'headerText', 'loginButton', 'navBar'],
        tabIndex: -1,
      },
      headerLogoText: {
        id: 'headerLogoText',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Optum',
          align: 'left',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 18,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#FF612B',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 93,
          height: 34,
          xLocation: 31,
          yLocation: 15,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'black',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      headerText: {
        id: 'headerText',
        type: 'Text',
        config: {
          visible: true,
          align: 'left',
          backgroundData: { style: 'None', color: 'transparent' },
          backgroundImage: { url: '', alt: '' },
          bold: true,
          borderData: { thickness: 0, radius: 0 },
          centerAlign: false,
          characterSpacing: 0,
          font: 'OptumSans',
          fontFamily: 'OptumSans',
          fontSize: 14,
          height: 29,
          iconBackgroundColor: '#d9f6fa',
          isHorizonWidget: true,
          italic: false,
          leftAlign: false,
          lineColor: 'black',
          lineSpacing: 1,
          marginTop: 0,
          minHeight: 10,
          minWidth: 10,
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          rightAlign: false,
          spacing: { top: '', right: '', bottom: '', left: '' },
          strikeThrough: false,
          text: 'Optum Insight Developer',
          textColor: '#0C55B8',
          textHighlightColor: '',
          toolTip: '',
          underline: false,
          uniqueId: 'headerText',
          width: 249,
          xLocation: 31,
          yLocation: 51,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerSection: {
        id: 'footerSection',
        type: 'Section',
        defaultWidget: true,
        config: {
          visible: true,
          enableFullScreen: false,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#FBF9F4',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 0,
            blurRadius: 0,
            spreadRadius: 0,
          },
          boxShadowColor: 'transparent',
          width: 1275,
          height: 231,
          xLocation: 0,
          yLocation: 1310,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: [
          'footerImage',
          'footerLink1',
          'footerDivider',
          'footerText2',
          'footerLink2',
          'footerLink3',
          'footerText1',
        ],
        tabIndex: -1,
      },
      footerImage: {
        id: 'footerImage',
        type: 'Image',
        defaultWidget: true,
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/529/OptumLogo1.png',
            alt: 'OptumLogo1.png',
          },
          width: 83,
          height: 27,
          xLocation: 31,
          yLocation: 1348,
          minWidth: 10,
          minHeight: 10,
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'black',
          enableFullScreen: false,
          marginTop: 0,
          parentId: 'footerSection',
        },
        children: [],
        tabIndex: -1,
      },
      footerLink1: {
        id: 'footerLink1',
        type: 'Link',
        defaultWidget: true,
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link1',
          url: '',
          newTab: false,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '123abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 207,
          yLocation: 1347,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#db1d1d',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          currentTab: 0,
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: false,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '1abc',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: '',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        children: [],
        tabIndex: -1,
      },
      footerDivider: {
        id: 'footerDivider',
        type: 'Divider',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          width: 1,
          height: 169,
          minWidth: 1,
          minHeight: 1,
          xLocation: 158,
          yLocation: 1345,
          uniqueId: '123abc',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          direction: 'Vertical',
          thickness: '10',
          lineColor: '#9D9F9F',
          enableFullScreen: false,
          parentId: 'footerSection',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerText2: {
        id: 'footerText2',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: '© 2024 All rights reserved',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 101,
          height: 80,
          xLocation: 31,
          yLocation: 1432,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerLink2: {
        id: 'footerLink2',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link2',
          currentTab: 0,
          url: '',
          newTab: true,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '2abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 310,
          yLocation: 1347,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '2abc',
            borderData: {
              thickness: 0,
              radius: 0,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: 'Link2',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        defaultWidget: true,
        children: [],
        tabIndex: -1,
      },
      footerLink3: {
        id: 'footerLink3',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link3',
          url: '',
          newTab: true,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '3abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 412,
          yLocation: 1347,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          currentTab: 0,
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '3abc',
            borderData: {
              thickness: 0,
              radius: 0,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: '',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        defaultWidget: true,
        children: [],
        tabIndex: -1,
      },
      footerText1: {
        id: 'footerText1',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'This is placeholder text. This is where you should place your legal text and/or privacy notice. It is recommended that you consult with your legal advisors for text that is relevant and adequate for your context and use. UI Toolkit cannot offer advice on legal text or privacy notices, this is outside our remit as a design system. Thank you for using UI Toolkit.',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 1040,
          height: 80,
          xLocation: 207,
          yLocation: 1429,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      Template1Image: {
        id: 'Template1Image',
        type: 'Image',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/377/6e28cb4d-7bd4-4354-b221-9be0d66993b0/banner.svg',
            alt: 'banner.svg',
          },
          width: 1275,
          height: 467,
          xLocation: -1,
          yLocation: 99,
          minWidth: 10,
          minHeight: 10,
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          enableFullScreen: false,
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template1Button: {
        id: 'Template1Button',
        type: 'Button',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          iconStyle: 'Without Icon',
          icon: {
            url: '',
            alt: '',
          },
          iconPlacement: 'Left',
          width: 308,
          height: 64,
          xLocation: 52,
          yLocation: 378,
          minWidth: 75,
          minHeight: 85,
          text: '',
          align: 'center',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: true,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          borderData: {
            thickness: 0,
            radius: 10,
          },
          paddingData: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          backgroundData: {
            style: 'None',
            color: '',
          },
          parentId: '',
          currentTab: 0,
          displayText: 'Regular Text',
          newTab: false,
          checked: '',
          url: '',
          iconColor: '',
          hoverData: {
            iconStyle: 'Without Icon',
            icon: {
              url: '',
              alt: '',
            },
            iconPlacement: 'Left',
            width: 240,
            height: 80,
            xLocation: 10,
            yLocation: 10,
            minWidth: 20,
            minHeight: 20,
            text: '',
            align: 'center',
            bold: false,
            italic: false,
            leftAlign: false,
            rightAlign: false,
            centerAlign: true,
            toolTip: '',
            fontSize: 12,
            font: 'OptumSans',
            underline: false,
            strikeThrough: false,
            characterSpacing: 0,
            fontFamily: 'OptumSans',
            lineSpacing: 1,
            textColor: '',
            textHighlightColor: '',
            iconBackgroundColor: 'white',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            paddingData: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
            uniqueId: '123abc',
            lineColor: 'red',
            backgroundData: {
              style: 'None',
              color: '',
            },
            parentId: '',
            site: null,
            displayText: 'Hover Text',
          },
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template1Text5: {
        id: 'Template1Text5',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Text Goes Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '28',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#3e45a9',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 296,
          height: 54,
          xLocation: 53,
          yLocation: 210,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template1Text4: {
        id: 'Template1Text4',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Header Text Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '24',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#3d44ab',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 277,
          height: 45,
          xLocation: 40,
          yLocation: 604,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template1Text3: {
        id: 'Template1Text3',
        type: 'Text',
        config: {
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: '1.5',
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 1210,
          height: 111,
          xLocation: 39,
          yLocation: 678,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template1Section: {
        id: 'Template1Section',
        type: 'Section',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 0,
            blurRadius: 0,
            spreadRadius: 0,
          },
          boxShadowColor: 'transparent',
          width: 1275,
          height: 500,
          xLocation: -1,
          yLocation: 813,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: [
          'Template1Text2',
          'Template1Text1',
          'Template1SectionText1',
          'Template1SectionText2',
          'Template1SectionTextInput1',
          'Template1SectionTextInput2',
          'Template1SectionTextInput3',
          'Template1SectionButton',
          'template1Form',
        ],
        tabIndex: -1,
      },
      Template1Text2: {
        id: 'Template1Text2',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Header Text Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '24',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#4231b4',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 277,
          height: 45,
          xLocation: 38,
          yLocation: 908,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: undefined,
        },
        children: [],
        tabIndex: -1,
      },
      Template1Text1: {
        id: 'Template1Text1',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: '1.5',
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 636,
          height: 250,
          xLocation: 38,
          yLocation: 984,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      template1Form: {
        id: 'template1Form',
        type: 'Form',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          borderData: {
            thickness: 0,
            radius: 5,
          },
          width: 530,
          height: 442,
          xLocation: 718,
          yLocation: 818,
          uniqueId: 'form123',
          lineColor: 'black',
          conditionalEmailEnabled: false,
          emailTo: '',
          subject: '',
          timeFrameUnit: '',
          timeFrameValue: '',
          conditionalEmailConfig: [],
          marginTop: 0,
        },
        children: [
          'Template1SectionText1',
          'Template1SectionText2',
          'Template1SectionTextInput1',
          'Template1SectionTextInput2',
          'Template1SectionTextInput3',
          'Template1SectionButton',
        ],
        tabIndex: -1,
      },
      Template1SectionText1: {
        id: 'Template1SectionText1',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Your Form Title',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 24,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 400,
          height: 49,
          xLocation: 746,
          yLocation: 827,
          minWidth: 180,
          minHeight: 40,
          backgroundData: {
            style: 'None',
            color: 'white',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          parentId: undefined,
        },
        children: [],
        tabIndex: -1,
      },
      Template1SectionText2: {
        id: 'Template1SectionText2',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'All fields with an asterisk (*) are required',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 400,
          height: 40,
          xLocation: 746,
          yLocation: 876,
          minWidth: 180,
          minHeight: 40,
          backgroundData: {
            style: 'None',
            color: 'white',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          parentId: undefined,
        },
        children: [],
        tabIndex: -1,
      },
      Template1SectionTextInput1: {
        id: 'Template1SectionTextInput1',
        type: 'TextInput',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: '',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          width: 481,
          height: 85,
          xLocation: 746,
          yLocation: 915,
          minWidth: 100,
          minHeight: 40,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          backgroundColor: 'white',
          required: true,
          label: 'Town',
          parentId: undefined,
          value: '',
          validation: 'None',
        },
        children: [],
        tabIndex: -1,
      },
      Template1SectionTextInput2: {
        id: 'Template1SectionTextInput2',
        type: 'TextInput',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: '',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          width: 481,
          height: 85,
          xLocation: 746,
          yLocation: 1001,
          minWidth: 180,
          minHeight: 30,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          backgroundColor: 'white',
          required: true,
          label: 'State',
          parentId: undefined,
          value: '',
          validation: 'None',
        },
        children: [],
        tabIndex: -1,
      },
      Template1SectionTextInput3: {
        id: 'Template1SectionTextInput3',
        type: 'TextInput',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: '',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          width: 481,
          height: 85,
          xLocation: 746,
          yLocation: 1088,
          minWidth: 180,
          minHeight: 30,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          backgroundColor: 'white',
          required: false,
          label: 'Contact Number',
          parentId: undefined,
          value: '',
          validation: 'None',
        },
        children: [],
        tabIndex: -1,
      },
      Template1SectionButton: {
        id: 'Template1SectionButton',
        type: 'Button',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          iconStyle: 'Without Icon',
          icon: {
            url: '',
            alt: '',
          },
          iconPlacement: 'Left',
          width: 180,
          height: 60,
          xLocation: 896,
          yLocation: 1184,
          minWidth: 180,
          minHeight: 60,
          text: 'Submit',
          align: 'center',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: true,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: 'white',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          borderData: {
            thickness: 0,
            radius: 50,
          },
          paddingData: {
            top: 5,
            bottom: 5,
            left: 5,
            right: 5,
          },
          uniqueId: '123abc',
          lineColor: '',
          backgroundData: {
            style: 'None',
            color: '#002677',
          },
          parentId: undefined,
          currentTab: 0,
          displayText: 'Regular Text',
          newTab: false,
          checked: '',
          url: '',
          iconColor: '',
          hoverData: {
            iconStyle: 'Without Icon',
            icon: {
              url: '',
              alt: '',
            },
            iconPlacement: 'Left',
            width: 240,
            height: 80,
            xLocation: 10,
            yLocation: 10,
            minWidth: 20,
            minHeight: 20,
            text: '',
            align: 'center',
            bold: false,
            italic: false,
            leftAlign: false,
            rightAlign: false,
            centerAlign: true,
            toolTip: '',
            fontSize: 12,
            font: 'OptumSans',
            underline: false,
            strikeThrough: false,
            characterSpacing: 0,
            fontFamily: 'OptumSans',
            lineSpacing: 1,
            textColor: '',
            textHighlightColor: '',
            iconBackgroundColor: 'white',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            paddingData: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
            uniqueId: '123abc',
            lineColor: 'red',
            backgroundData: {
              style: 'None',
              color: '',
            },
            parentId: '',
            site: null,
            displayText: 'Hover Text',
          },
          action: 'submit',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      loginButton: {
        id: 'loginButton',
        type: 'LoginButton',
        copyable: false,
        defaultWidget: true,
        config: {
          visible: true,
          backgroundImage: { url: '', alt: '' },
          backgroundData: { style: 'None', color: 'transparent' },
          borderData: { thickness: 0, radius: 0 },
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          uniqueId: 'loginButton',
          width: 150,
          height: 75,
          xLocation: 1094,
          yLocation: 12,
          minWidth: 20,
          minHeight: 10,
          signInOptionEnabled: 'notEnabled',
          signInOptionTitle: 'Sign In Options',
          signInLinkColor: 'black',
          signInOptionLinks: [],
          lineColor: 'black',
          signInType: 'button',
          signInIcon: 'MoreVertical',
          fontSize: 12,
          font: 'OptumSans',
          buttonColor: '#c0e9ff',
          bold: false,
          italic: false,
          underline: false,
          strikeThrough: false,
          simulateLoggedOffUser: true,
          isHorizonWidget: true,
          parentId: 'headerSection',
        },
        children: [],
        tabIndex: -1,
      },
      navBar: {
        id: 'navBar',
        type: 'Navigation',
        defaultWidget: true,
        config: {
          visible: true,
          backgroundData: { style: 'Color', color: '#FBF9F4' },
          backgroundImage: { url: '', alt: '' },
          borderData: { radius: 0, thickness: 0 },
          dividerLineColor: '#DADBDC',
          enableFullScreen: false,
          font: 'OptumSans',
          fontSize: 12,
          height: 75,
          width: 129,
          xLocation: 965,
          yLocation: 12,
          minHeight: 30,
          minWidth: 40,
          iconColor: 'black',
          isHorizonWidget: true,
          lineColor: 'black',
          menuItems: [
            {
              displayText: 'Home',
              icon: { alt: '', url: '' },
              id: 1,
              isSection: false,
              pageSelected: null,
              tooltip: '',
              type: 'page',
              parentSectionId: null,
              url: '/home',
              visible: true,
            },
          ],
          showDividerLines: true,
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          style: 'Horizontal',
          textColor: 'black',
          treeOrder: [1],
          parentId: 'headerSection',
        },
        children: [],
        tabIndex: -1,
      },
    },
  },
  {
    name: GENERAL_TEMPLATE_NAME,
    icon: undefined,
    widgets: {
      root: {
        id: 'root',
        type: 'List',
        config: { visible: true, bgcolor: 'transparent' },
        children: [
          'Template2Button1',
          'Template2Video',
          'Template2Text6',
          'Template2Divider',
          'Template2Text7',
          'Template2Link',
          'Template2Text1',
          'Template2Text8',
          'Template2Text2',
          'Template2Text9',
          'Template2Section',
          'Template2Text3',
          'Template2Text4',
          'Template2Text5',
          'Template2Image',
          'footerText1',
          'footerText2',
          'footerDivider',
          'headerSection',
          'headerText',
          'headerLogoText',
          'footerSection',
          'footerImage',
          'footerLink1',
          'footerLink2',
          'footerLink3',
          'Template2Button',
          'navBar',
          'loginButton',
        ],
        permanent: true,
        defaultWidget: true,
      },
      headerSection: {
        id: 'headerSection',
        type: 'Section',
        defaultWidget: true,
        config: {
          visible: true,
          enableFullScreen: false,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#FBF9F4',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 4,
            blurRadius: 5,
            spreadRadius: 0,
          },
          boxShadowColor: 'lightgrey',
          width: 1275,
          height: 102,
          xLocation: 0,
          yLocation: -1,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: ['headerLogoText', 'headerText', 'loginButton', 'navBar'],
        tabIndex: -1,
      },
      headerLogoText: {
        id: 'headerLogoText',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Optum',
          align: 'left',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 18,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#FF612B',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 93,
          height: 34,
          xLocation: 31,
          yLocation: 15,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'black',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      headerText: {
        id: 'headerText',
        type: 'Text',
        config: {
          visible: true,
          align: 'left',
          backgroundData: { style: 'None', color: 'transparent' },
          backgroundImage: { url: '', alt: '' },
          bold: true,
          borderData: { thickness: 0, radius: 0 },
          centerAlign: false,
          characterSpacing: 0,
          font: 'OptumSans',
          fontFamily: 'OptumSans',
          fontSize: 14,
          height: 29,
          iconBackgroundColor: '#d9f6fa',
          isHorizonWidget: true,
          italic: false,
          leftAlign: false,
          lineColor: 'black',
          lineSpacing: 1,
          marginTop: 0,
          minHeight: 10,
          minWidth: 10,
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          rightAlign: false,
          spacing: { top: '', right: '', bottom: '', left: '' },
          strikeThrough: false,
          text: 'Optum Insight Developer',
          textColor: '#0C55B8',
          textHighlightColor: '',
          toolTip: '',
          underline: false,
          uniqueId: 'headerText',
          width: 249,
          xLocation: 31,
          yLocation: 51,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerSection: {
        id: 'footerSection',
        type: 'Section',
        defaultWidget: true,
        config: {
          visible: true,
          enableFullScreen: false,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#FBF9F4',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 0,
            blurRadius: 0,
            spreadRadius: 0,
          },
          boxShadowColor: 'transparent',
          width: 1275,
          height: 231,
          xLocation: 0,
          yLocation: 1744,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: [
          'footerImage',
          'footerLink1',
          'footerDivider',
          'footerText2',
          'footerLink2',
          'footerLink3',
          'footerText1',
        ],
        tabIndex: -1,
      },
      footerImage: {
        id: 'footerImage',
        type: 'Image',
        defaultWidget: true,
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/529/OptumLogo1.png',
            alt: 'OptumLogo1.png',
          },
          width: 83,
          height: 27,
          xLocation: 31,
          yLocation: 1782,
          minWidth: 10,
          minHeight: 10,
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'black',
          enableFullScreen: false,
          marginTop: 0,
          parentId: 'footerSection',
        },
        children: [],
        tabIndex: -1,
      },
      footerLink1: {
        id: 'footerLink1',
        type: 'Link',
        defaultWidget: true,
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link1',
          url: '',
          newTab: false,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '123abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 207,
          yLocation: 1781,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#db1d1d',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          currentTab: 0,
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: false,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '1abc',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: '',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        children: [],
        tabIndex: -1,
      },
      footerDivider: {
        id: 'footerDivider',
        type: 'Divider',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          width: 1,
          height: 169,
          minWidth: 1,
          minHeight: 1,
          xLocation: 158,
          yLocation: 1779,
          uniqueId: '123abc',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          direction: 'Vertical',
          thickness: '10',
          lineColor: '#9D9F9F',
          enableFullScreen: false,
          parentId: 'footerSection',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerText2: {
        id: 'footerText2',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: '© 2024 All rights reserved',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 101,
          parentId: 'footerSection',
          height: 80,
          xLocation: 31,
          yLocation: 1866,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      footerLink2: {
        id: 'footerLink2',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link2',
          currentTab: 0,
          url: '',
          newTab: true,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '2abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 310,
          yLocation: 1781,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '2abc',
            borderData: {
              thickness: 0,
              radius: 0,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: '',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        defaultWidget: true,
        children: [],
        tabIndex: -1,
      },
      footerLink3: {
        id: 'footerLink3',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Link3',
          url: '',
          newTab: true,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: true,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#0015fa',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '3abc',
          lineColor: 'black',
          width: 90,
          height: 40,
          xLocation: 412,
          yLocation: 1781,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#0016ff',
          selectedPage: null,
          marginTop: 0,
          parentId: 'footerSection',
          currentTab: 0,
          hoverData: {
            font: 'OptumSans',
            fontFamily: 'OptumSans',
            fontSize: 12,
            bold: true,
            italic: false,
            underline: true,
            strikeThrough: false,
            textColor: '#0015fa',
            lineSpacing: 1,
            characterSpacing: 0,
            uniqueId: '3abc',
            borderData: {
              thickness: 0,
              radius: 0,
            },
            lineColor: '#ff0000',
            iconBackgroundColor: '',
            displayText: '',
            paddingData: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        defaultWidget: true,
        children: [],
        tabIndex: -1,
      },
      footerText1: {
        id: 'footerText1',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'This is placeholder text. This is where you should place your legal text and/or privacy notice. It is recommended that you consult with your legal advisors for text that is relevant and adequate for your context and use. UI Toolkit cannot offer advice on legal text or privacy notices, this is outside our remit as a design system. Thank you for using UI Toolkit.',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          parentId: 'footerSection',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 1040,
          height: 80,
          xLocation: 207,
          yLocation: 1863,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        defaultWidget: true,
        tabIndex: -1,
      },
      Template2Image: {
        id: 'Template2Image',
        type: 'Image',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/377/6e28cb4d-7bd4-4354-b221-9be0d66993b0/banner.svg',
            alt: 'banner.svg',
          },
          width: 1275,
          height: 467,
          xLocation: -1,
          yLocation: 99,
          minWidth: 10,
          minHeight: 10,
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          enableFullScreen: false,
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Button: {
        id: 'Template2Button',
        type: 'Button',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          iconStyle: 'Without Icon',
          icon: {
            url: '',
            alt: '',
          },
          iconPlacement: 'Left',
          width: 308,
          height: 64,
          xLocation: 52,
          yLocation: 438,
          minWidth: 75,
          minHeight: 85,
          text: '',
          align: 'center',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: true,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          borderData: {
            thickness: 0,
            radius: 10,
          },
          paddingData: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          backgroundData: {
            style: 'None',
            color: '',
          },
          parentId: '',
          currentTab: 0,
          displayText: 'Regular Text',
          newTab: false,
          checked: '',
          url: '',
          iconColor: '',
          hoverData: {
            iconStyle: 'Without Icon',
            icon: {
              url: '',
              alt: '',
            },
            iconPlacement: 'Left',
            width: 240,
            height: 80,
            xLocation: 10,
            yLocation: 10,
            minWidth: 20,
            minHeight: 20,
            text: '',
            align: 'center',
            bold: false,
            italic: false,
            leftAlign: false,
            rightAlign: false,
            centerAlign: true,
            toolTip: '',
            fontSize: 12,
            font: 'OptumSans',
            underline: false,
            strikeThrough: false,
            characterSpacing: 0,
            fontFamily: 'OptumSans',
            lineSpacing: 1,
            textColor: '',
            textHighlightColor: '',
            iconBackgroundColor: 'white',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            paddingData: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
            uniqueId: '123abc',
            lineColor: 'red',
            backgroundData: {
              style: 'None',
              color: '',
            },
            parentId: '',
            site: null,
            displayText: 'Hover Text',
          },
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text5: {
        id: 'Template2Text5',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Text Goes Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '28',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#3e45a9',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 296,
          height: 54,
          xLocation: 53,
          yLocation: 210,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text4: {
        id: 'Template2Text4',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Header Text Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '24',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#3d44ab',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 277,
          height: 45,
          xLocation: 40,
          yLocation: 604,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text3: {
        id: 'Template2Text3',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: '1.5',
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 1200,
          height: 111,
          xLocation: 39,
          yLocation: 678,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Section: {
        id: 'Template2Section',
        type: 'Section',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          backgroundData: {
            style: 'Color',
            color: '#07f3ff1a',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          boxShadowData: {
            horizontal: 0,
            vertical: 0,
            blurRadius: 0,
            spreadRadius: 0,
          },
          boxShadowColor: 'transparent',
          width: 1275,
          height: 200,
          xLocation: 2,
          yLocation: 1546,
          minWidth: 10,
          minHeight: 10,
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: 'section345',
          marginTop: 0,
        },
        children: ['Template2Text2', 'Template2Text1'],
        tabIndex: -1,
      },
      Template2Text2: {
        id: 'Template2Text2',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Header Text Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '18',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#4231b4',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 215,
          height: 34,
          xLocation: 33,
          yLocation: 1578,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: 'Template2Section',
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text1: {
        id: 'Template2Text1',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: '1.5',
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 743,
          height: 86,
          xLocation: 32,
          yLocation: 1629,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: 'Template2Section',
        },
        children: [],
        tabIndex: -1,
      },
      Template2Link: {
        id: 'Template2Link',
        type: 'Link',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          displayText: 'Learn more about Text Here >',
          url: '',
          newTab: false,
          bold: true,
          italic: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#302c97',
          iconBackgroundColor: '#d9f6fa',
          checked: 'URL',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          uniqueId: '123abc',
          lineColor: 'black',
          width: 257,
          height: 33,
          xLocation: 38,
          yLocation: 814,
          minWidth: 20,
          minHeight: 20,
          iconColor: '#2c3ca4',
          selectedPage: null,
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text7: {
        id: 'Template2Text7',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Video',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#2d34cc',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 55,
          height: 29,
          xLocation: 594,
          yLocation: 894,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Divider: {
        id: 'Template2Divider',
        type: 'Divider',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          width: 1275,
          height: 1,
          minWidth: 1,
          minHeight: 1,
          xLocation: 0,
          yLocation: 871,
          uniqueId: '123abc',
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          direction: 'Horizontal',
          thickness: '10',
          lineColor: '#000000',
          enableFullScreen: false,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text6: {
        id: 'Template2Text6',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Header Text Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '32',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#353fa8',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 359,
          height: 54,
          xLocation: 452,
          yLocation: 959,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Video: {
        id: 'Template2Video',
        type: 'VideoUpload',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          width: 679,
          height: 406,
          xLocation: 54,
          yLocation: 1103,
          minWidth: 10,
          minHeight: 10,
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
        },
        children: [],
        tabIndex: -1,
      },
      Template2Button1: {
        id: 'Template2Button1',
        type: 'Button',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          iconStyle: 'Without Icon',
          icon: {
            url: '',
            alt: '',
          },
          iconPlacement: 'Left',
          width: 240,
          height: 80,
          xLocation: 808,
          yLocation: 1344,
          minWidth: 75,
          minHeight: 85,
          text: '',
          align: 'center',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: true,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: 'white',
          borderData: {
            thickness: 0,
            radius: 10,
          },
          paddingData: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          backgroundData: {
            style: 'None',
            color: '',
          },
          parentId: undefined,
          currentTab: 0,
          displayText: 'Regular Text',
          newTab: false,
          checked: '',
          url: '',
          iconColor: '',
          hoverData: {
            iconStyle: 'Without Icon',
            icon: {
              url: '',
              alt: '',
            },
            iconPlacement: 'Left',
            width: 240,
            height: 80,
            xLocation: 10,
            yLocation: 10,
            minWidth: 20,
            minHeight: 20,
            text: '',
            align: 'center',
            bold: false,
            italic: false,
            leftAlign: false,
            rightAlign: false,
            centerAlign: true,
            toolTip: '',
            fontSize: 12,
            font: 'OptumSans',
            underline: false,
            strikeThrough: false,
            characterSpacing: 0,
            fontFamily: 'OptumSans',
            lineSpacing: 1,
            textColor: '',
            textHighlightColor: '',
            iconBackgroundColor: 'white',
            borderData: {
              thickness: 0,
              radius: 10,
            },
            paddingData: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
            uniqueId: '123abc',
            lineColor: 'red',
            backgroundData: {
              style: 'None',
              color: '',
            },
            parentId: '',
            site: null,
            displayText: 'Hover Text',
          },
          marginTop: 0,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text9: {
        id: 'Template2Text9',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text: 'Header Text Here',
          align: 'none',
          bold: true,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: '18',
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: 1,
          textColor: '#4231b4',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 273,
          height: 45,
          xLocation: 808,
          yLocation: 1141,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: undefined,
        },
        children: [],
        tabIndex: -1,
      },
      Template2Text8: {
        id: 'Template2Text8',
        type: 'Text',
        config: {
          visible: true,
          spacing: {
            top: '',
            right: '',
            bottom: '',
            left: '',
          },
          isHorizonWidget: true,
          text:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and',
          align: 'none',
          bold: false,
          italic: false,
          leftAlign: false,
          rightAlign: false,
          centerAlign: false,
          toolTip: '',
          fontSize: 12,
          font: 'OptumSans',
          underline: false,
          strikeThrough: false,
          characterSpacing: 0,
          fontFamily: 'OptumSans',
          lineSpacing: '1.5',
          textColor: '',
          textHighlightColor: '',
          iconBackgroundColor: '#d9f6fa',
          width: 450,
          height: 103,
          xLocation: 808,
          yLocation: 1217,
          minWidth: 10,
          minHeight: 10,
          backgroundData: {
            style: 'None',
            color: 'transparent',
          },
          backgroundImage: {
            url: '',
            alt: '',
          },
          paddingData: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          borderData: {
            thickness: 0,
            radius: 0,
          },
          uniqueId: '123abc',
          lineColor: 'red',
          marginTop: 0,
          parentId: undefined,
        },
        children: [],
        tabIndex: -1,
      },
      loginButton: {
        id: 'loginButton',
        type: 'LoginButton',
        copyable: false,
        defaultWidget: true,
        config: {
          visible: true,
          backgroundImage: { url: '', alt: '' },
          backgroundData: { style: 'None', color: 'transparent' },
          borderData: { thickness: 0, radius: 0 },
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          uniqueId: 'loginButton',
          width: 150,
          height: 75,
          xLocation: 1094,
          yLocation: 12,
          minWidth: 20,
          minHeight: 10,
          signInOptionEnabled: 'notEnabled',
          signInOptionTitle: 'Sign In Options',
          signInLinkColor: 'black',
          signInOptionLinks: [],
          lineColor: 'black',
          signInType: 'button',
          signInIcon: 'MoreVertical',
          fontSize: 12,
          font: 'OptumSans',
          buttonColor: '#c0e9ff',
          bold: false,
          italic: false,
          underline: false,
          strikeThrough: false,
          simulateLoggedOffUser: true,
          isHorizonWidget: true,
          parentId: 'headerSection',
        },
        children: [],
        tabIndex: -1,
      },
      navBar: {
        id: 'navBar',
        type: 'Navigation',
        defaultWidget: true,
        config: {
          visible: true,
          backgroundData: { style: 'Color', color: '#FBF9F4' },
          backgroundImage: { url: '', alt: '' },
          borderData: { radius: 0, thickness: 0 },
          dividerLineColor: '#DADBDC',
          enableFullScreen: false,
          font: 'OptumSans',
          fontSize: 12,
          height: 75,
          width: 129,
          xLocation: 965,
          yLocation: 12,
          minHeight: 30,
          minWidth: 40,
          iconColor: 'black',
          isHorizonWidget: true,
          lineColor: 'black',
          menuItems: [
            {
              displayText: 'Home',
              icon: { alt: '', url: '' },
              id: 1,
              isSection: false,
              pageSelected: null,
              tooltip: '',
              type: 'page',
              parentSectionId: null,
              url: '/home',
              visible: true,
            },
          ],
          showDividerLines: true,
          paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
          style: 'Horizontal',
          textColor: 'black',
          treeOrder: [1],
          parentId: 'headerSection',
        },
        children: [],
        tabIndex: -1,
      },
    },
  },
];

export default templates;
