import { Config } from './types';
import VideoUpload from './VideoUpload';
import VideoUploadControl from './VideoUploadControl';
import {
  defaultBorderData,
  defaultPaddingData,
  defaultSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'VideoUpload',
  displayName: 'VideoUpload',
  icon: 'ImageWithText',
  initialConfig: {
    ...defaultSizeLocationData,
    ...defaultPaddingData,
    ...defaultBorderData,
    uniqueId: '123abc',
    file: undefined,
    lineColor: 'red',
    visible: true,
  },
  components: {
    render: VideoUpload,
    control: VideoUploadControl,
  },
  copyable: true,
  isHorizonWidget: true,
} as HorizonWidgetDefinition<Config>;
