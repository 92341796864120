import React, { useState, useEffect, MouseEvent } from 'react';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/ColorPicker/ColorPicker';
import styled from 'styled-components';
import { Config } from 'shared/widgets/CalendarWidget/types';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { ColorButtonWrapper } from 'builder/components/WidgetConfigurations/Background/Background';
import { MainLabelStyles } from 'builder/components/WidgetConfigurations/Typography/Typography';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export interface CalendarColorSelector<T> {
  pages?: Array<Page>;
  site?: Site;
  widgetState?: Widget<T>;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleCalendarAccentColor: (data: string) => void;
}

const CalendarColorSelector = (props: CalendarColorSelector<Config>) => {
  const {
    handleCalendarAccentColor,
    widgetState,
    site,
    updateSite,
    saveSite,
  } = props;

  const [calendarAccent, setCalendarAccent] = useState(
    widgetState.config.calendarAccentColor
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover-tabs' : undefined;

  //   useEffect(() => {
  //     handleCalendarAccentColor(widgetState.config.calendarAccentColor);
  //   }, [widgetState.config]);

  //   useEffect(() => {
  //     handleCalendarAccentColor(calendarAccentColor);
  //   }, [calendarAccentColor]);

  useEffect(() => {
    setCalendarAccent(widgetState.config.calendarAccentColor);
  }, [widgetState.config]);

  useEffect(() => {
    handleCalendarAccentColor(calendarAccent);
  }, [calendarAccent]);

  const handleColorEditClick = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
      toast('Gradient cannot be used for accent color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      setCalendarAccent(newColor);
    }
  };

  return (
    <>
      <StyledWrapper>
        <MainLabelStyles>Calendar Accent Color</MainLabelStyles>
        <ColorButtonWrapper
          data-test-id="color-icon"
          id="button-color"
          onClick={handleColorEditClick}
        >
          <ColorButton color={calendarAccent} />
        </ColorButtonWrapper>
      </StyledWrapper>
      <StyledPickerPopover
        data-test-id="widget-color-picker-popover"
        id={pickerId}
        open={pickerOpen}
        onClick={handlePickerClick}
        onClose={handlePickerClose}
        anchorEl={pickerAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <StyledPickerWrapper>
          <StyledPickerHeader data-test-id="styled-picker-header">
            Color Selector
          </StyledPickerHeader>
          <StyledClose data-test-id="close-icon" onClick={handlePickerClose} />
        </StyledPickerWrapper>
        <GradientColorPicker
          value={calendarAccent}
          site={site}
          handleColorChange={handleColorChange}
          onChange={handleColorChange}
          updateSite={updateSite}
          saveSite={saveSite}
          anchorEl={pickerAnchorEl}
        />
      </StyledPickerPopover>
    </>
  );
};

export default CalendarColorSelector;
