import React, { ChangeEvent, useState } from 'react';
import { FormControl, Select } from '@uitk/react';
import styled from 'styled-components';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import {
  backgroundStyles,
  buttonWidgetBackgroundStyles,
} from 'builder/util/constants';
import S3Image from 'builder/components/S3Image/S3Image';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoPreview from './LogoPreview';
import { MainLabelStyles } from '../Typography/Typography';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from '../../ColorPicker/ColorPicker';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';

export const BackgroundStyleDropdowns = styled.div`
  display: flex;
  align-items: center;
`;

const BackgroundStyle = styled(Select)`
  margin-right: 0.5rem;
  font-family: OptumSans;
  width: 12rem;
  border-radius: 0.375rem;
  padding-left: 25px;
  .kASQuK {
    margin-top: 0;
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
`;

export const BackgroundColorWrapper = styled.div`
  display: flex;
`;

const S3ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  padding-top: 10px;
  padding-left: 10px;
`;

const S3ImageControlsWrapper = styled.div`
  padding-top: 1em;
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  padding-left: 14px;
`;

export const ColorButtonWrapper = styled.div``;

export interface BackgroundData {
  style: string;
  color?: string;
}

export interface BackgroundProps<T> {
  backgroundData: (data: BackgroundData) => void;
  backgroundImage?: (data: Image) => void;
  handleLineColor?: (data: string) => void;
  handleBackgroundColor?: (data: string) => void;
  widgetState: Widget<T>;
  site: Site;
  updateSite: (site: Site) => void;
  saveSite: () => void;
  currentTab?: number;
}

export function getBackgroundColor(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.backgroundData.color;
    case 1:
      return widgetState.config.hoverData?.backgroundData?.color;
    default:
      return {
        style: widgetState.config.backgroundData.style,
        color: widgetState.config.backgroundData.color,
      };
  }
}

export function getBackgroundData(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.backgroundData;
    case 1:
      return widgetState.config.hoverData?.backgroundData;
    default:
      return {
        style: widgetState.config.backgroundData.style,
        color: widgetState.config.backgroundData.color,
      };
  }
}

export const Background: React.FC<BackgroundProps<any>> = (
  props: BackgroundProps<any>
) => {
  const {
    widgetState,
    site,
    currentTab,
    updateSite,
    saveSite,
    backgroundData,
    backgroundImage,
    handleLineColor,
    handleBackgroundColor,
  } = props;

  const [_selectedColor, setSelectedColor] = useState(
    getBackgroundColor(widgetState, currentTab)
  );
  const [image, setImage] = useState({
    url: widgetState.config.backgroundImage?.url,
    alt: widgetState.config.backgroundImage?.alt,
  });
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;
  const [background, setBackground] = useState(
    getBackgroundData(widgetState, currentTab)
  );
  React.useEffect(() => {
    setImage({
      url: widgetState.config.backgroundImage?.url,
      alt: widgetState.config.backgroundImage?.alt,
    });
  }, [widgetState.config.backgroundImage?.url]);
  React.useEffect(() => {
    setBackground(getBackgroundData(widgetState, currentTab));
    setSelectedColor(getBackgroundColor(widgetState, currentTab));
  }, [widgetState.config]);

  const handleBackgroundStyleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'None' && widgetState.type === 'Button') {
      setBackground(prevState => ({
        ...prevState,
        style: 'None',
        color: '#002667',
      }));
      backgroundData({ ...background, style: 'None', color: '#002667' });
    } else if (e.target.value === 'None') {
      setBackground(prevState => ({
        ...prevState,
        style: 'None',
        color: 'transparent',
      }));
      backgroundData({ ...background, style: 'None', color: 'transparent' });
    } else {
      setBackground(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      backgroundData({ ...background, [e.target.name]: e.target.value });
    }
  };

  const handleImageChange = (image: Image) => {
    setImage(image);
    widgetState.type !== 'Button' ? backgroundImage(image) : '';
    toast('Image upload succeeded', { type: 'success', theme: 'colored' });
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = (newColor: string) => {
    if (pickerAnchorEl === document.querySelector('#line-color')) {
      setSelectedColor({ style: 'Color', color: newColor });
      handleLineColor(newColor);
    }
    if (pickerAnchorEl === document.querySelector('#background-color')) {
      setSelectedColor({ style: 'Color', color: newColor });
      handleBackgroundColor(newColor);
    }
  };

  return (
    <>
      <BackgroundStyleDropdowns>
        <MainLabelStyles>Style</MainLabelStyles>
        <FormControl
          data-test-id="background-style-dropdown"
          id={'controlled-select'}
        >
          <BackgroundStyle
            onChange={handleBackgroundStyleChange}
            value={background?.style}
            name="style"
          >
            {widgetState.type === 'Button' || widgetState.type === 'Link'
              ? buttonWidgetBackgroundStyles.map((style, index) => (
                  <option key={index} value={style}>
                    {style}
                  </option>
                ))
              : backgroundStyles.map((style, index) => (
                  <option key={index} value={style}>
                    {style}
                  </option>
                ))}
          </BackgroundStyle>
        </FormControl>
        {background?.style === 'Color' && (
          <>
            <BackgroundColorWrapper>
              <ColorButtonWrapper
                id="background-color"
                onClick={handleColorEditClick}
              >
                <ColorButton
                  color={getBackgroundColor(widgetState, currentTab)}
                />
              </ColorButtonWrapper>
              <StyledPickerPopover
                data-test-id="widget-color-picker-popover"
                id={pickerId}
                open={pickerOpen}
                onClick={handlePickerClick}
                onClose={handlePickerClose}
                anchorEl={pickerAnchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              >
                <StyledPickerWrapper>
                  <StyledPickerHeader data-test-id="styled-picker-header">
                    Color Selector
                  </StyledPickerHeader>
                  <StyledClose
                    data-test-id="close-icon"
                    onClick={handlePickerClose}
                  />
                </StyledPickerWrapper>
                <GradientColorPicker
                  value={getBackgroundColor(widgetState, currentTab)}
                  onChange={handleColorChange}
                  site={site}
                  handleColorChange={handleColorChange}
                  updateSite={updateSite}
                  saveSite={saveSite}
                  anchorEl={pickerAnchorEl}
                />
              </StyledPickerPopover>
            </BackgroundColorWrapper>
          </>
        )}
      </BackgroundStyleDropdowns>
      {background?.style === 'Image' && (
        <>
          <BackgroundStyleDropdowns>
            <MainLabelStyles>Image</MainLabelStyles>
            <S3ImageControlsWrapper>
              <S3Image
                image={image}
                label="Click to Upload"
                data-test-id="background-image"
                onUpload={handleImageChange}
              />
              <S3ImagePreviewWrapper>
                {image && (
                  <LogoPreview url={image.url} maxWidth={50} maxHeight={50} />
                )}
              </S3ImagePreviewWrapper>
            </S3ImageControlsWrapper>
          </BackgroundStyleDropdowns>
        </>
      )}
    </>
  );
};
