//@ts-nocheck
import React, { ReactElement, useEffect, useState } from 'react';
import { Config } from './types';

import Loading from '../../components/Loading';

const MarkdownWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  useEffect(() => {
    const fetchMarkdown = async () => {
      // onChange({
      //   ...value,
      //   .widgetState.config.markdownContent: startingMD,
      // });
      // if(!url.length) {
      //   setMarkdownContent('Add a valid URL to fetch markdown content');
      //   return;
      // }
      // try {
      //   const response = await fetch(
      //     // url
      //     'https://raw.githubusercontent.com/OptumInsight-Platform/oi-core-platform-docs/main/docs/ecp/ecp-email-services.md?token=GHSAT0AAAAAACQQHDVXGMK2ZGOOT7OQSYS6ZUZOUCA'
      //   );
      //   if (!response.ok) {
      //     throw new Error('Failed to fetch markdown content');
      //   }
      //   const text = await response.text();
      //   console.log(text);
      //   // setMarkdownContent(text);
      // } catch (error) {
      //   console.error('Error fetching markdown:', error);
      //   setMarkdownContent('Failed to load markdown content');
      // }
    };
    fetchMarkdown();
  }, []);

  // const handleChange = () => {
  //   const { onChange } = props;
  //   const { config } = props.widgetState;
  //   onChange(
  //     {
  //       ...config,
  //       markdownContent: markdownContent,
  //     },
  //     props.widgetState.id,
  //     props.widgetState.defaultWidget
  //   );
  // };

  const lorem = ``;
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setItems(lorem);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  // return (
  // <MDXEditor
  //   markdown={props.widgetState.config.markdownContent}
  //   plugins={isEditingMarkdown ? editingPlugins : readOnlyPlugins}
  //   onChange={newMarkdown => {
  //     setMarkdownContent(newMarkdown);
  //     // calculateNewPositions();
  //   }}
  //   readOnly={!isEditingMarkdown}
  //   onBlur={handleChange}
  // />
  // );
  // return <Remark>{markdownContent}</Remark>

  return (
    <>
      {props.widgetState.config.visible !== false && (
        <div style={{ fontFamily: 'OptumSans', fontWeight: 'normal' }}>
          {isLoading && <Loading />}
          <div>{items}</div>
        </div>
      )}
    </>
  );
};

export default MarkdownWidget;
