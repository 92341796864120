import { Dispatch } from 'shared/state';
import { connect } from 'react-redux';
import AccordionWidget from './AccordionWidget';
import { closeAccordionPanel, openAccordionPanel } from 'shared/state/ducks/ui';
import { changeWidgetVisible } from '../../state/ducks/sites';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openAccordionPanel: (widgetId: string, height: number) => {
    dispatch(openAccordionPanel(widgetId, height));
  },
  closeAccordionPanel: (widgetId: string, height: number) => {
    dispatch(closeAccordionPanel(widgetId, height));
  },
  changeWidgetVisible: (
    siteId: number,
    pageId: number,
    parentWidgetId: string,
    widgetId: string,
    visible: boolean,
    currentTabIndex?: number | string
  ) => {
    dispatch(
      changeWidgetVisible(
        siteId,
        pageId,
        parentWidgetId,
        widgetId,
        visible,
        currentTabIndex
      )
    );
  },
});

export default connect(null, mapDispatchToProps)(AccordionWidget);
