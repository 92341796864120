import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledFormWidget = styled.div``;

const FormWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
    visible,
    isCustomWidget,
  } = props.widgetState.config;

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledFormWidget
      id={'formWidget'}
      className={'formWidget'}
      style={{
        backgroundColor:
          backgroundData?.color.startsWith('#') ||
          backgroundData?.color.startsWith('rgb')
            ? backgroundData?.color
            : '',
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : backgroundData?.style === 'Color' &&
              !(
                backgroundData?.color.startsWith('#') ||
                backgroundData?.color.startsWith('rgb')
              )
            ? backgroundData?.color
            : '',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundClip: 'content-box',
        width: '100%',
        height: '100%',
      }}
    />
  );
};
export default FormWidget;
