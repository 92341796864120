import React, { ReactElement } from 'react';
import { Config } from './types';
import styled from 'styled-components';
import Calendar from './Calendar';

const StyledCalendar = styled.div<{
  backgroundData: any;
  backgroundImage: any;
  borderData: any;
  lineColor: string;
}>`
  margin-top: 0;
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p.backgroundImage?.url}')`
      : ''};
  background-color: ${p =>
    p.backgroundData?.color !== '' ? p.backgroundData?.color : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  height: 100%;
  width: 100%;
  box-shadow: none;
`;

const CalendarWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    backgroundData,
    backgroundImage,
    borderData,
    lineColor,
    width,
    height,
    calendarAccentColor,
    fontFamily,
  } = props.widgetState.config;

  if (
    props.widgetState.config.visible === false ||
    props.widgetState.config.isCustomWidget
  ) {
    return <></>;
  }

  return (
    <StyledCalendar
      backgroundData={backgroundData}
      backgroundImage={backgroundImage}
      borderData={borderData}
      lineColor={lineColor}
      style={{
        backgroundColor:
          backgroundData?.color.startsWith('#') ||
          backgroundData?.color.startsWith('rgb')
            ? backgroundData?.color
            : '',
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : backgroundData?.style === 'Color' &&
              !(
                backgroundData?.color.startsWith('#') ||
                backgroundData?.color.startsWith('rgb')
              )
            ? backgroundData?.color
            : '',
      }}
    >
      {/* Calendar content */}
      <Calendar
        width={width}
        height={height}
        accentColor={calendarAccentColor}
        fontFamily={fontFamily}
      />
    </StyledCalendar>
  );
};

export default CalendarWidget;
