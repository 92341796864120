import Radio from './RadioButton';
import { Config } from './types';
import RadioButtonControl from './RadioButtonControl';
import {
  defaultBorderData,
  defaultSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'Radio',
  displayName: 'Radio',
  icon: 'pipe-section',
  initialConfig: {
    ...defaultSizeLocationData,
    ...defaultBorderData,
    uniqueId: 'radio123',
    value: 'Option 1, Option 2, Option 3',
    lineColor: 'none',
    label: 'Label',
    required: false,
    buttonColor: '#002677',
    width: 345,
    height: 85,
    minHeight: 50,
    minWidth: 50,
    checkedValue: '',
    visible: true,
  },
  components: {
    render: Radio,
    control: RadioButtonControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
