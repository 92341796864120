import TextWidget from './TextWidget';
import { Config } from './types';
import TextWidgetControl from 'shared/widgets/Text/TextWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultPaddingData,
  defaultSizeLocationData,
  defaultTypographyData,
} from 'builder/util/constants';

export default {
  type: 'Text',
  displayName: 'Text',
  icon: 'TextSize',
  initialConfig: {
    ...defaultTypographyData,
    ...defaultSizeLocationData,
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultPaddingData,
    ...defaultBorderData,
    uniqueId: '123abc',
    lineColor: 'red',
    visible: true,
  },
  components: {
    render: TextWidget,
    control: TextWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
