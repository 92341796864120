import { FC, useEffect, useState } from 'react';
import {
  Dialog,
  Dropdown,
  FormControl,
  IDropdownItem,
  Label,
  Text,
  TextInput,
} from '@uitk/react';
import styled from 'styled-components';
import { CONDITIONAL_EMAIL_DIALOG_TITLE } from 'builder/util/constants';
import { StyledButton } from 'builder/scenes/SiteBuilder/CreateSiteDialog/CreateSiteDialog';
import { ConditionalEmail } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPage } from 'shared/state/ducks/sites';
import { closeConditionalEmailDialog } from 'shared/state/ducks/ui';
import { RootState } from 'shared/state/reducer';
import { IconTrash } from '@tabler/icons-react';

const StyledDialog = styled(Dialog)`
  z-index: 1000;
  & h1 {
    font-family: OptumSans;
  }
  .uitk-dialog {
    font-family: OptumSans;
  }
  .uitk-button {
    font-family: OptumSans;
  }
  .Dialogcomponent__Body {
    width: 100%;
    position: relative;
  }
  #text-input {
    font-family: OptumSans;
  }
  #form-field-select-text {
    font-family: OptumSans;
  }
  #dropdown-multi-controlled-text {
    font-family: OptumSans;
  }
  & #text-input {
    padding: 0.6rem;
  }
  & [data-testid^='asterisk-icon'] {
    display: none;
  }
  #form-field-select + ul {
    position: fixed;
    width: 15rem;
  }
  & div.uitk-dropdown > div > button + div {
    position: fixed;
    width: 15rem;
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
  & > button {
    box-shadow: 0 0 0 1px #333333;
  }
`;

const StyledText = styled(Text)`
  font-size: 14pt;
  padding-top: 2rem;
  font-style: italic;
  font-weight: 700;
`;

const StyledParagraph = styled(Text)`
  font-size: 11pt;
`;

export const StyledIcon = styled.div`
  height: 1em;
  cursor: pointer;
  padding-top: 1.5rem;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  font-family: OptumSans;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
`;

export interface ConditionalEmailDialogProps {
  siteId: number;
  currentPage: PageVersion;
  formWidget: any;
  eligibleFormWidgets: Array<any>;
  conditionalEmailConfig: Array<ConditionalEmail>;
}

export interface ConditionalEmailItem extends ConditionalEmail {
  eligibleFormFields: Array<IDropdownItem>;
  eligibleFormFieldValues: Array<IDropdownItem>;
  selectedFormField: IDropdownItem;
  selectedFormFieldValues: Array<IDropdownItem>;
  error: { email: string; formField: string; formFieldValues: string };
}

const ConditionalEmailDialog: FC<ConditionalEmailDialogProps> = (
  props: ConditionalEmailDialogProps
) => {
  const [emailConfig, setEmailConfig] = useState<Array<ConditionalEmailItem>>([
    {
      email: '',
      formField: '',
      formFieldValues: [''],
      eligibleFormFields: [],
      eligibleFormFieldValues: [],
      selectedFormField: null,
      selectedFormFieldValues: [],
      error: { email: '', formField: '', formFieldValues: '' },
    },
  ]);

  const dispatch = useDispatch();

  const open = useSelector(
    (state: RootState) => state.ui.conditionalEmailDialog
  );

  const basicEmailValidation = new RegExp(
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$'
  );

  useEffect(() => {
    if (props.conditionalEmailConfig?.length > 0) {
      const initialFields: Array<ConditionalEmailItem> = props.conditionalEmailConfig.map(
        config => ({
          email: config.email,
          formField: config.formField,
          formFieldValues: config.formFieldValues,
          eligibleFormFields: props.eligibleFormWidgets.map(widget => ({
            id: widget.id,
            label: widget.config.label,
            value: widget.config.label,
          })),
          eligibleFormFieldValues: props.eligibleFormWidgets
            .find(widget => widget?.config?.label === config.formField)
            .config.value.split(',')
            .map((option, index) => ({
              id: index,
              label: option,
              value: option,
            })),

          selectedFormField: {
            id: props.eligibleFormWidgets.find(
              widget => widget.config.label === config.formField
            )?.id,
            label: config.formField,
            value: config.formField,
          },
          selectedFormFieldValues: config.formFieldValues.map(
            (value, index) => ({ id: index, label: value, value: value })
          ),
          error: { email: '', formField: '', formFieldValues: '' },
        })
      );
      setEmailConfig(initialFields);
    } else {
      setEmailConfig([
        {
          email: '',
          formField: '',
          eligibleFormFields: props.eligibleFormWidgets.map(widget => ({
            id: widget.id,
            label: widget.config.label,
            value: widget.config.label,
          })),
          eligibleFormFieldValues: [],
          selectedFormField: null,
          selectedFormFieldValues: [],
          error: { email: '', formField: '', formFieldValues: '' },
        },
      ]);
    }
  }, [props.conditionalEmailConfig]);

  const handleAddInput = () => {
    setEmailConfig([
      ...emailConfig,
      {
        email: '',
        formField: '',
        formFieldValues: [''],
        eligibleFormFields: props.eligibleFormWidgets.map(widget => ({
          id: widget.id,
          label: widget.config.label,
          value: widget.config.label,
        })),
        eligibleFormFieldValues: [],
        selectedFormField: null,
        selectedFormFieldValues: [],
        error: { email: '', formField: '', formFieldValues: '' },
      },
    ]);
  };

  const handleRemoveInput = (index: number) => {
    const newFields = emailConfig.filter((_, i) => i !== index);
    setEmailConfig(newFields);
  };

  const handleInputChange = (index: number, e) => {
    const newFields = emailConfig.map((config, i) =>
      i === index
        ? {
            ...config,
            email: e,
            error: { ...config.error, email: '' },
          }
        : config
    );
    setEmailConfig(newFields);
  };

  function handleSingleSelectChange(index: number, value) {
    const eligibleFormFieldValues: Array<IDropdownItem> = props.eligibleFormWidgets
      .find(widget => widget.id === value.id)
      ?.config?.value.split(',')
      .map((option, index) => ({
        id: index,
        label: option.trim(),
        value: option.trim(),
      }));
    const newFields = emailConfig.map((config, i) =>
      i === index
        ? {
            ...config,
            formField: value.value,
            selectedFormField: {
              id: value.id,
              label: value.value,
              value: value.value,
            },
            error: { ...config.error, formField: '' },
            selectedFormFieldValues: [],
            eligibleFormFieldValues: eligibleFormFieldValues,
          }
        : config
    );
    setEmailConfig(newFields);
  }

  const handleMultiSelectChange = (index: number, newValue) => {
    const newFields = emailConfig.map((config, i) =>
      i === index
        ? {
            ...config,
            error: { ...config.error, formFieldValues: '' },
            formFieldValues:
              newValue.length > 0
                ? newValue.map(value => value.value)
                : newValue.value,
          }
        : config
    );
    setEmailConfig(newFields);
  };

  const validateAndSetErrors = () => {
    const newConfig = emailConfig.map(config => {
      const newItem = { ...config, error: { ...config.error } };
      const emailArray = newItem.email.replace(/\s/g, '').split(',');
      const emailValidationResult =
        emailArray.length > 1
          ? emailArray.every(email => basicEmailValidation.test(email))
          : basicEmailValidation.test(newItem.email);
      newItem.error.email = emailValidationResult
        ? ''
        : 'Invalid e-mail address';
      newItem.error.formField =
        newItem.formField === '' ? 'Selection is required' : '';
      newItem.error.formFieldValues =
        !newItem.formFieldValues || newItem.formFieldValues?.length === 0
          ? 'Selection is required'
          : '';
      return newItem;
    });
    setEmailConfig(newConfig);
    return newConfig.some(
      value =>
        value.error.email !== '' ||
        value.error.formField !== '' ||
        value.error.formFieldValues !== ''
    );
  };

  function modifyFormWidgetConfig(page: PageContent, widgetId: string) {
    const updatedEmailConfig: Array<ConditionalEmail> = emailConfig.map(
      config => {
        return {
          email: config.email,
          formField: config.formField,
          formFieldValues: config.formFieldValues,
        };
      }
    );
    const newPage = { ...page };

    for (const key in newPage) {
      if (newPage.hasOwnProperty(key)) {
        const widget = newPage[key];
        if (widget.id === widgetId) {
          newPage[key].config.conditionalEmailConfig = updatedEmailConfig;
          newPage[key].config.conditionalEmailEnabled = true;
        }
      }
    }
    return newPage;
  }

  const handleSave = () => {
    const invalid = validateAndSetErrors();
    if (invalid) {
      return;
    } else {
      const modifiedPageContent = modifyFormWidgetConfig(
        props.currentPage.content,
        props.formWidget.id
      );
      const updatedPage: PageVersion = {
        ...props.currentPage,
        content: modifiedPageContent,
      };
      dispatch(updateCurrentPage(props.siteId, updatedPage));
      dispatch(closeConditionalEmailDialog());
    }
  };
  const handleClose = () => {
    dispatch(closeConditionalEmailDialog());
  };

  if (
    props.formWidget.config.visible === false ||
    props.formWidget.config.isCustomWidget
  ) {
    return <></>;
  }

  return (
    <>
      {open.open && (
        <StyledDialog
          open={open.open}
          title={CONDITIONAL_EMAIL_DIALOG_TITLE}
          titleAs="h1"
          onClose={handleClose}
          data-test-id="conditional-email-dialog"
        >
          <Dialog.Body>
            <StyledParagraph>
              To customize your e-mail preferences to where specific form
              entries are sent, start by entering an e-mail address (or
              multiple, separated by commas) for which the condition will be
              applied, then select your desired form field and field response.
            </StyledParagraph>
            {emailConfig.length > 0 ? (
              emailConfig.map((config, index) => (
                <>
                  <StyledFormWrapper key={index}>
                    <StyledFormControl
                      id={'text-input'}
                      error={config.error.email}
                      required
                    >
                      <Label>E-mail Address</Label>
                      <TextInput
                        value={config.email}
                        onChange={e => handleInputChange(index, e.target.value)}
                        data-test-id="email-input"
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      id={'form-field-select'}
                      error={config.error.formField}
                      required
                    >
                      <Label>Form Field</Label>
                      <Dropdown
                        type="single"
                        items={config.eligibleFormFields}
                        onChange={e => handleSingleSelectChange(index, e)}
                        value={config.selectedFormField}
                        data-test-id="form-field-select"
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      id="dropdown-multi-controlled"
                      error={config.error.formFieldValues}
                      required
                    >
                      <Label>Form Field Options</Label>
                      <Dropdown
                        disabled={!config.selectedFormField}
                        type="multi"
                        items={config.eligibleFormFieldValues}
                        onChange={e => handleMultiSelectChange(index, e)}
                        value={config.selectedFormFieldValues}
                        data-test-id="values-select"
                      />
                    </StyledFormControl>
                    <StyledIcon
                      data-test-id="delete-condition-icon"
                      title="Delete Condition"
                      onClick={() => handleRemoveInput(index)}
                    >
                      <IconTrash size={24} />
                    </StyledIcon>
                  </StyledFormWrapper>
                </>
              ))
            ) : (
              <StyledText>
                No conditional e-mail configurations found. Click "Add
                Condition" below to configure new conditions for form responses.
              </StyledText>
            )}
          </Dialog.Body>
          <Dialog.Actions>
            <ButtonDiv>
              <StyledButton
                data-test-id="add-button"
                inverse
                onPress={handleAddInput}
              >
                + Add condition
              </StyledButton>
              <StyledButton
                data-test-id="save-button"
                onPress={handleSave}
                disabled={emailConfig.some(
                  config =>
                    config.error.email !== '' ||
                    config.error.formField !== '' ||
                    config.error.formFieldValues !== ''
                )}
              >
                Apply changes
              </StyledButton>
            </ButtonDiv>
          </Dialog.Actions>
        </StyledDialog>
      )}
    </>
  );
};

export default ConditionalEmailDialog;
