import LinkWidget from './LinkWidget';
import { Config } from './types';
import LinkWidgetControl from 'shared/widgets/Link/LinkWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultLinkData,
  defaultLinkWidgetHoverData,
  defaultPaddingData,
} from 'builder/util/constants';

export default {
  type: 'Link',
  displayName: 'Link',
  icon: 'TextSize',
  initialConfig: {
    ...defaultLinkData,
    ...defaultPaddingData,
    ...defaultBorderData,
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    uniqueId: '123abc',
    lineColor: 'transparent',
    width: 170,
    height: 50,
    xLocation: 10,
    yLocation: 10,
    minWidth: 20,
    minHeight: 20,
    iconColor: 'black',
    selectedPage: null,
    currentTab: 0,
    displayText: 'My Link Text',
    hoverData: {
      ...defaultLinkWidgetHoverData,
    },
    uploadedFile: {
      name: '',
      url: '',
    },
    visible: true,
  },
  components: {
    render: LinkWidget,
    control: LinkWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
