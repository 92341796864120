import { BackgroundData } from 'builder/components/WidgetConfigurations/Background/Background';
import { BorderData } from 'builder/components/WidgetConfigurations/Border';
import { PaddingData } from 'builder/components/WidgetConfigurations/Padding';
import { IconType } from 'link-ui-react';
import { User } from 'shared/state/misc/oidc';
import { FeedbackFormField } from 'shared/widgets/SimpleForm/types';
import { Alignment } from 'shared/widgets/Text/types';
import { TilesResponse } from 'shared/api/myapps';
import { ConditionalEmail } from 'shared/widgets/Form/types';
import { PageBackground } from 'builder/scenes/SiteBuilder/PagesPanel/PagesPanel';

export enum SigninElementTypes {
  Button = 'button',
  Link = 'link',
}

export enum ProfileIconType {
  ArrowDown = 'ArrowDown',
  MoreVertical = 'MoreVertical',
}

export enum AppRegistrationStatus {
  Provisioned = 0,
  Initial = 2,
  Pending = 6,
  Rejected = 5,
}

export enum HeaderVariant {
  UTILITY = 'utility',
  UTILITY_WITH_NAV = 'utilityWithNav',
  UTILITY_WITH_NAV_IN_HEADER = 'utilityWithNavInHeader',
}

export enum NavRenderType {
  FLAT = 'flat',
  NESTED = 'nested',
  WIDE = 'wide',
}

export enum defaultOptumUHCFontTypes {
  OptumSans = 'OptumSans',
  UHCSans = 'UHCSans',
  UHCSerifHeadline = 'UHCSerifHeadline',
}

export enum AskIdConstants {
  ASKID_NOT_LISTED = 'AskId Not Listed',
  APP_NOT_LISTED = 'Application Not Listed',
  SELECT_A_ASKID = 'Select...',
  LISTED_ASKID = 'listed_askid',
  UNLISTED_ASKID = 'unlisted_aski',
}

declare global {
  interface StateMeta {
    pending?: boolean;
    successful?: boolean;
    error?: string;
    retrievedAt?: number | null;
  }

  interface DefaultRecord {
    timestamp: Date;
    domain: string;
  }

  interface IdentityProviderUser {
    family_name: string;
    given_name: string;
    username: string;
    isSuperUser: boolean;
    idp: Array<IdentityProvider>;
    terms: Array<TermsAndConditions>;
  }

  interface IdentityProvider {
    userId: string;
    identityProvider: any;
    userName: string;
  }

  interface Tenancy {
    tenantId: string;
    tenantDesc?: string;
    siteId?: string;
    siteDesc?: string;
  }

  interface TermsAndConditions {
    objectType: string;
    acceptanceStatus: 'Y' | 'N';
    active: boolean;
    objectId: number;
    url: string;
    version: string;
    linkUuid: string;
  }

  interface PageAction {
    action:
      | 'login'
      | 'logout'
      | 'click'
      | 'render'
      | 'selection'
      | 'myapps_retry'
      | 'start_impersonation'
      | 'end_impersonation';
    loginType?: 'button' | 'redirect' | 'silent';
  }

  interface PageData {
    page: string;
    authenticated: boolean;
    user: string;
  }

  interface SiteData {
    id: number;
    siteName: string;
    createdBy: string;
    modifiedDate: string;
    lastModifiedByUser: string;
    publishedDate: string;
    lastPublishedByUser: string;
    published: boolean;
    domain: string;
  }

  interface WidgetRenderProps<T> {
    className?: string;
    site?: Site;
    pages?: Array<Page>;
    page?: Page;
    widgetState: Widget<T>;
    editing?: boolean;
    currentPage?: PageVersion;
    addChild?: (type: string, initialConfig: T, location: number) => void;
    adoptChild?: (widgetId: string, location: number) => void;
    dragging?: boolean;
    widgetId?: string;
    tracking?: any;
    value?: T;
    tilesResponse?: TilesResponse;
    onChange?: (value: T, widgetId: string, defaultWidget: boolean) => void;
    user?: User;
    siteId?: number;
    openAccordionPanel?: (widgetId: string, height: number) => void;
    closeAccordionPanel?: (widgetId: string, height: number) => void;
    initiateLogout?: () => void;
    changeWidgetVisible?: (
      siteId: number,
      pageId: number,
      parentWidgetId: string,
      widgetId: string,
      visible: boolean,
      currentTabIndex?: number | string
    ) => void;
    setActiveNavLink?: (navLinkId: string) => void;
  }

  interface WidgetControlProps<T> {
    value: T;
    onChange: (value: T, widgetId?: string) => void;
    widgetState: Widget<T>;
    addChild?: (
      type: string,
      initialConfig: T,
      location: number,
      widgetId?: string,
      newParentWidgetId?: string,
      tabIndex?: number
    ) => void;
    removeWidget?: (widgetId: string) => void;
    site?: Site;
    pages?: Array<Page>;
    updateSite?: (site: Site) => void;
    saveSite?: () => void;
    closeConfigPanel?: (open: boolean) => void;
    currentPage?: PageVersion;
    updateCurrentPage?: (pageVersion: PageVersion) => void;
    updateWidgetPosition?: (
      bannerWidgetId: string,
      height: number,
      oldHeight: number
    ) => void;
    enableRegister?: boolean;
    appRegistration?: AppRegistration;
    noAppsMessage?: string;
    enableAppEnvironment?: boolean;
    appsEnvironmentList?: string;
  }

  interface WidgetDefinition<T> {
    type: string;
    displayName: string;
    icon: string;
    initialConfig: T;
    copyable: boolean;
    components: {
      control?: React.ComponentClass<WidgetControlProps<T>>;
      render?: React.ComponentClass<WidgetRenderProps<T>>;
    };
  }
  interface HorizonWidgetDefinition<T> {
    type: string;
    displayName: string;
    icon: string;
    initialConfig: T;
    copyable: boolean;
    components: {
      control?: React.FC<WidgetControlProps<T>>;
      render?: React.FC<WidgetRenderProps<T>>;
    };
  }

  interface Widget<T> {
    id: string;
    className?: string;
    type: string;
    children: Array<string>;
    config: T;
    permanent?: boolean;
    parentId?: string;
    tabIndex?: number;
    panelIndex?: number;
    defaultWidget?: boolean;
    copyable?: boolean;
    zIndex?: number;
    isWidgetDuplicated?: boolean;
    pageBackground?: PageBackground;
  }

  interface NewWidgetDropItem<T> {
    type: string;
    config: T;
  }

  interface ExistingWidgetDropItem {
    id: string;
  }

  interface WidgetMap {
    [index: string]: Widget<any>;
  }

  interface FormWidget {
    widgetId: string;
    recipientEmails: Array<String>;
    enableConditionalEmailTo: boolean;
    conditionalEmailList: Array<ConditionalEmail>;
  }

  interface HoverData {
    iconStyle: string;
    icon: Image;
    iconPlacement: string;
    text: string;
    font: any;
    fontFamily: any;
    fontSize: any;
    bold: any;
    italic: any;
    underline: any;
    strikeThrough: any;
    textColor: string;
    textHighlightColor: string;
    align: Alignment;
    leftAlign: any;
    centerAlign: any;
    rightAlign: any;
    lineSpacing: any;
    characterSpacing: any;
    width: number;
    height: number;
    minWidth: number;
    minHeight: number;
    xLocation: number;
    yLocation: number;
    uniqueId: string;
    borderData: BorderData;
    lineColor: string;
    iconBackgroundColor: string;
    iconAltText?: string;
    backgroundData?: BackgroundData;
    action?: string;
    parentId?: string;
    displayText: string;
  }

  interface LinkWidgetHoverData {
    font: any;
    fontFamily: any;
    fontSize: any;
    bold: any;
    italic: any;
    underline: any;
    strikeThrough: any;
    textColor: string;
    lineSpacing: any;
    characterSpacing: any;
    uniqueId: string;
    borderData: BorderData;
    lineColor: string;
    iconBackgroundColor: string;
    backgroundData?: BackgroundData;
    action?: string;
    parentId?: string;
    paddingData: PaddingData;
  }

  interface Page {
    id: number;
    current: PageVersion;
    versions: Array<PageVersion>;
  }

  interface PageVersion {
    id: number;
    pageId: number;
    slug: string;
    title: string;
    allowedIdps: Array<string>;
    authentication: boolean;
    headerEnabled: boolean;
    footerEnabled: boolean;
    status: 'draft' | 'published' | 'archived';
    content: PageContent;
    forms?: {
      create?: Array<String>;
      delete?: Array<String>;
      update?: Array<String>;
    };
    pageBackground?: PageBackground;
  }

  interface AccessRequest {
    userName: string;
    name: string;
    userEmail: string;
    accessReason: string;
    organization: string;
    orgUrl: string;
    siteName: string;
  }

  interface AdminEmailForm {
    formActionType: string;
    accessRequestForm: AccessRequest;
    emailAction: EmailAction;
  }

  interface EmailAction {
    subject: string;
    templateName: string;
    emailTo: Array<string>;
  }

  interface PageContent {
    root: Widget<any>;
    [index: string]: Widget<any>;
  }

  interface Preset {
    name: string;
    icon: string;
    widgets: PageContent;
  }

  interface Color {
    name: string;
    value: string;
    type?: string;
    id: string;
  }
  export type Font = {
    size: {
      body: {
        base: {
          value: number;
        };
      };
    };
    weight: {
      base: {
        value: string;
      };
    };
    family: {
      base: {
        value: string;
      };
    };
  };

  interface Theme {
    maxWidth?: string;
    font?: Font;
    logo?: Image;
    logoTitle?: string;
    logoTitleFontColor?: string;
    logoTitleFont?: string;
    logoTitleFontStyle?: string;
    logoTextPlacement?: string;
    logoVerticalAlignment?: string;
    favicon?: Image;
    colors: {
      primary: string;
      secondary: string;
      tertiary: string;
      quaternary?: string;
      custom: Color[];
      text?: {
        primary: string;
        secondary: string;
      };
      original?: {
        primary: string;
        secondary: string;
        tertiary: string;
        quaternary?: string;
        custom: Color[];
      };
      aux?: {
        lightestBlue?: string;
        lightBlue?: string;
        blue?: string;
        darkBlue?: string;
        darkNavyBlue?: string;
        darkestBlue?: string;
        black?: string;
        darkGrey?: string;
        lightGrey?: string;
        grey?: string;
        offWhite?: string;
        green?: string;
        lightGreen?: string;
        lighterGreen?: string;
        yellow?: string;
        lightYellow?: string;
        brightYellow?: string;
        pink?: string;
        lightPink?: string;
        red?: string;
        lightRed?: string;
        blueGrey?: string;
        white?: string;
        lighterGrey?: string;
        lightestGrey?: string;
        mutedGrey?: string;
        skyBlue?: string;
      };
    };
  }

  interface HorizonTheme {
    name: 'UHC' | 'Optum' | 'Rally' | 'Custom';
    font: Font;
    colors: {
      primary: Color;
      secondary: Color;
      tertiary: Color;
      quaternary: Color;
      custom: Color[];
    };
  }

  interface Image {
    url: string;
    alt: string;
  }

  interface Access {
    read: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean;
  }

  interface Organization {
    orgId: string;
    name: string;
  }

  interface Product {
    name: string;
    productId: string;
  }
  interface Site {
    id: number;
    name: string;
    theme: Theme;
    homepageId: number;
    header?: Header;
    footer?: Footer;
    domain: string;
    domainExtension?: string;
    clientId?: string;
    idpHint?: string;
    idpFlag?: string;
    orgAccount?: Organization;
    postLoginPageId?: number;
    postLoginUrl?: string;
    postLoginType?: 'page' | 'url';
    postLogoutPage?: PostLogoutPage;
    published?: boolean;
    archived?: boolean;
    createdByUser?: string;
    lastModifiedByUser?: string;
    apps?: Array<any>;
    access?: Access;
    aideId?: string;
    isOwner?: boolean;
    lastModifiedDate?: number;
    lastPublishedByUser?: string;
    lastPublishedDate?: number;
    siteProvisioningStores?: Array<number>;
    prevSiteProvisioningStores?: Array<number>;
    product?: Product;
    impersonationEnabled?: boolean;
    prevAlerts?: Array<Alert>;
    alerts?: Array<Alert>;
    tenantId?: string;
    horizonhomepageId?: number;
    horizonpostLoginPageId?: number;
    tenancy?: Tenancy;
  }

  interface OptionType {
    value: string;
    label: string;
  }

  interface ProvisionedSite {
    id: number;
    name: string;
    domain: string;
  }

  interface UserSites {
    site: Array<ProvisionedSite>;
    error: Array<string>;
  }

  interface ProvisioningStore {
    id: number;
    provStoreName: string;
  }

  interface Tenancies {
    tenantId: string;
    tenantDesc: string;
    siteId: string;
    siteDesc: string;
  }

  interface Footer {
    categories?: Array<FooterLink>;
    items?: Array<NavItem>;
    variant?: 'standard' | 'logo' | 'logoinfo' | 'flat';
    info?: string;
    social?: Array<FooterSocialLink>;
    bgcolor?: string;
    previewImage?: Image;
    pages?: any;
  }

  interface FooterLink {
    type?: 'url' | 'page' | 'category' | 'section';
    pageId?: number;
    label?: string;
    url?: string;
    links?: Array<FooterLink>;
  }

  interface FooterSocialLink {
    image: Image;
    title: string;
    url: string;
  }

  interface NavBarBoxShadow {
    borderShadowHorizontalOffset: string;
    borderShadowVerticalOffset: string;
    borderShadowBlurRadius: string;
    borderSpreadRadius: string;
    borderShadowColor: string;
  }

  interface Header {
    variant?: 'utility' | 'utilityWithNav' | 'utilityWithNavInHeader';
    stickyNavEnabled?: boolean;
    logoPosition?: 'left' | 'center';
    utilityBgColor?: string;
    navBgColor?: string;
    nav?: Navigation;
    signInOptionEnabled?: 'enabled' | 'notEnabled';
    signInOptionTitle?: string;
    signInLinkColor?: string;
    signInOptionLinks?: Array<SignInLink>;
    signInType?: SigninElementTypes;
    profileIcon?: IconType;
    navBarBoxShadow?: NavBarBoxShadow;
    feedback?: FeedbackFin;
  }

  interface FeedbackFin {
    feedbackEnable: boolean;
    feedbackAuthentication?: boolean;
    feedbackStyle?: string;
    feedbackButtonColor?: string;
    fontFamily?: string;
    fontSize?: string;
    fontColor?: string;
    underline?: Boolean;
    strikeThrough?: Boolean;
    issueFeedBackTypeEmailList?: string;
    ideaFeedbackTypeEmailList?: string;
    bugFeedbackTypeEmailList?: string;
    formFields?: Array<FeedbackFormField>;
  }

  interface SignInLink {
    label: string;
    url: string;
    openInNewTab?: boolean;
    type: string;
  }

  interface Navigation {
    items: Array<NavItem>;
    renderType?: NavRenderType;
    fontColor: string;
    lightMode?: boolean;
    breadCrumbs?: boolean;
  }

  interface NavItem {
    type: 'url' | 'page' | 'section';
    label?: string;
    url?: string;
    pageId?: number;
    openInNewTab?: boolean;
    allowedIdps?: Array<string>;
    children?: Array<NavItem>;
  }

  interface Notification {
    id: number;
    userId: number;
    siteId: number;
    url: string;
    imageURL: string;
    displayName: string;
    businessKey: string;
    messageText: string;
    type: NotificationType;
    status: NotificationStatus;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    themeColor: string;
  }

  interface Alert {
    id: number;
    type: Type;
    scope: Scope;
    pageId?: number;
    importance: Importance;
    title?: string;
    subtitle?: string;
    message?: string;
    bgColor?: string;
    startDate: Date;
    endDate: Date;
  }

  export type Type = 'pop-up' | 'push' | 'banner';

  export type Scope = 'site' | 'page';

  export type Importance = 'high' | 'med' | 'low';

  // TODO: specify exact types defined by the API
  interface NotificationType {
    id: number;
    name: string;
  }

  // TODO: specify exact statuses defined by the API
  interface NotificationStatus {
    id: number;
    name: string;
  }

  type NotificationCategory = {
    count: number;
    name: string;
    select?: (name: string) => void;
    active?: boolean;
  };

  interface Application {
    businessKey: string;
    tiles: Array<Tile>;
    themeColor: string;
    emulationEnabled: boolean;
  }

  interface Tile {
    tileId: string;
    type: string;
    description: string;
    active: boolean;
    internalName: string;
    activeDate: string; // TODO: Date?
    target: string; // TODO: Enum?
    popovertext: string;
    label: string;
    targetURL: string;
    image: string;
    iframesrc: string;
    assistant?: boolean;
    emulationEnabled: boolean;
    envName?: string;
  }

  interface PostLogoutPage {
    type: 'url' | 'page';
    url?: string;
    pageId?: number;
  }

  interface UserAndDate {
    user: string;
    date: number;
  }

  interface SiteTableData {
    id: number;
    name: string;
    domain: string;
    lastModified: { user: string; date: number };
    lastPublished: { user: string; date: number };
    createdByUser: string;
    actions: any[];
  }

  interface FormTypesData {
    createdTime: number;
    siteUrl: string;
    createdBy: string;
    formName: string;
    siteId: number;
    pageId: number;
    slug: string;
    formId: number;
  }

  interface FormMapData {
    [key: string]: string;
  }

  interface FormTableData {
    submittedTime: number;
    formData: FormMapData;
  }

  interface FormSubmissionRequest {
    widgetId: string;
    formData: FormMapData;
  }

  interface AppRegistration {
    businessKey: string;
    registerUrl?: string;
    registerMessage?: string;
    resumeRegisterMessage?: string;
    resumeRegisterUrl?: string;
  }

  interface AskId {
    askId: string;
    applicationName: string;
  }

  interface Result {
    record: AskId;
  }

  interface Pagination {
    page: number;
    perPage: number;
    total: number;
  }

  interface RootObject {
    results: Result[];
    pagination: Pagination;
  }
}

export {};
