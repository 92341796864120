import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledBannerWidget = styled.div``;

const BannerWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
    paddingData,
  } = props.widgetState.config;

  function background() {
    if (backgroundData.style === 'Color') {
      return backgroundData?.color;
    }
    if (backgroundData.style === 'Image') {
      return `url('${backgroundImage?.url}')`;
    }
    return 'transparent;';
  }

  if (
    props.widgetState.config.visible === false ||
    props.widgetState.config.isCustomWidget
  ) {
    return <></>;
  }

  return (
    <StyledBannerWidget
      id={'bannerWidget'}
      className={'bannerWidget'}
      style={{
        background: background(),
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundClip: 'content-box',
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
        height: 'inherit',
      }}
    ></StyledBannerWidget>
  );
};
export default BannerWidget;
