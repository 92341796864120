import { ActionCreator, AnyAction, Reducer } from 'redux';
import {
  Selector,
  Selector1,
  Selector2,
  Selector3,
  ThunkActionCreator,
  AsyncThunkActionCreator,
} from 'shared/state';
import uuidv4 from 'uuid/v4';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  saveSite as doSaveSite,
  createSite as doCreateSite,
  publishSite as doPublishSite,
  publishSiteToDemo as doPublishSiteToDemo,
  unPublishSite as doUnPublishSite,
  archiveSite as doArchiveSite,
  fetchSites as doFetchSites,
  fetchPages as doFetchPages,
  createPage as doCreatePage,
  savePage as doSavePage,
  saveAllPage as doSaveAllPage,
  removePage as doRemovePage,
  fetchSite as doFetchSite,
  fetchSitesByUuid as doFetchSitesByUuid,
} from 'shared/api/sites';
import {
  saveSiteProvisioningStores as doSaveSiteProvisioningStores,
  removeSiteProvisioningStores as doRemoveSiteProvisioningStores,
} from 'shared/api/siteProvisioningStores';
import {
  closeCreatePageDialog,
  closeCreateSiteDialog,
  openSiteListDialog,
} from './ui';
import { SiteListDialogType } from 'builder/components/SiteListDialog/SiteListDialog';
import templates from 'builder/scenes/SiteBuilder/CreateSiteDialog/templates';
import { FieldType } from 'shared/widgets/Form/types';
import { saveInitialPages, saveInitialSite, saveSitePages } from './site';
import { widgetTypes } from 'builder/util/constants';
/**
 * Widget actions
 */
export const ADD_CHILD = 'duplo/widgets/ADD_CHILD';
const ADD_SIBLING = 'duplo/widgets/ADD_SIBLING';
const ADOPT_CHILD = 'duplo/widgets/ADOPT_CHILD';
export const UPDATE_WIDGET_POSITION = 'duplo/widgets/UPDATE_WIDGET_POSITION';
const UPDATE_WIDGET_CONFIG = 'duplo/widgets/UPDATE_WIDGET_CONFIG';
const UPDATE_CURRENT_PAGE_WIDGET_CONFIG =
  'duplo/widgets/UPDATE_CURRENT_PAGE_WIDGET_CONFIG';
const UPDATE_DEFAULT_WIDGET_CONFIG =
  'duplo/widgets/UPDATE_DEFAULT_WIDGET_CONFIG';
const REMOVE_WIDGET = 'duplo/widgets/DELETE_WIDGET';
const REMOVE_WIDGET_DEFAULT = 'duplo/widgets/DELETE_WIDGET_DEFAULT';

/**
 * Page actions
 */
export const FETCH_PAGES_PENDING = 'duplo/pages/FETCH_PAGES_PENDING';
export const FETCH_PAGES_FULFILLED = 'duplo/pages/FETCH_PAGES_FULFILLED';
export const FETCH_PAGES_FAILED = 'duplo/pages/FETCH_PAGES_FAILED';

export const CREATE_PAGE_PENDING = 'duplo/pages/CREATE_PAGE_PENDING';
export const CREATE_PAGE_FULFILLED = 'duplo/pages/CREATE_PAGE_FULFILLED';
export const CREATE_PAGE_FAILED = 'duplo/pages/CREATE_PAGE_FAILED';

export const SAVE_PAGE_PENDING = 'duplo/pages/SAVE_PAGE_PENDING';
export const SAVE_PAGE_FULFILLED = 'duplo/pages/SAVE_PAGE_FULFILLED';
export const SAVE_PAGE_FAILED = 'duplo/pages/SAVE_PAGE_FAILED';

export const SAVE_ALL_PAGE_PENDING = 'duplo/pages/SAVE_ALL_PAGE_PENDING';
export const SAVE_ALL_PAGE_FULFILLED = 'duplo/pages/SAVE_ALL_PAGE_FULFILLED';
export const SAVE_ALL_PAGE_FAILED = 'duplo/pages/SAVE_ALL_PAGE_FAILED';

export const REMOVE_PAGE_PENDING = 'duplo/pages/REMOVE_PAGE_PENDING';
export const REMOVE_PAGE_FULFILLED = 'duplo/pages/REMOVE_PAGE_FULFILLED';
export const REMOVE_PAGE_FAILED = 'duplo/pages/REMOVE_PAGE_FAILED';

export const SET_HOME_PAGE = 'duplo/pages/SET_HOME_PAGE';

export const UPDATE_CURRENT_PAGE = 'duplo/sites/UPDATE_CURRENT_PAGE';
export const UPDATE_PAGE_DEFAULT_WIDGET =
  'duplo/sites/UPDATE_PAGE_DEFAULT_WIDGET';

/**
 * Site actions
 */
export const FETCH_SITES_PENDING = 'duplo/sites/FETCH_SITES_PENDING';
export const FETCH_SITES_FULFILLED = 'duplo/sites/FETCH_SITES_FULFILLED';
export const FETCH_SITES_FAILED = 'duplo/sites/FETCH_SITES_FAILED';
export const CHANGE_WIDGET_VISIBLE = 'CHANGE_WIDGET_VISIBLE';
export const FETCH_SITES_BY_UUID_PENDING =
  'duplo/sites/FETCH_SITES_BY_UUID_PENDING';
export const FETCH_SITES_BY_UUID_FULFILLED =
  'duplo/sites/FETCH_SITES_BY_UUID_FULFILLED';
export const FETCH_SITES_BY_UUID_FAILED =
  'duplo/sites/FETCH_SITES_BY_UUID_FAILED';
export const FETCH_SITE_PENDING = 'duplo/sites/FETCH_SITE_PENDING';
export const FETCH_SITE_FULFILLED = 'duplo/sites/FETCH_SITE_FULFILLED';
export const FETCH_SITE_FAILED = 'duplo/sites/FETCH_SITE_FAILED';

export const CREATE_SITE_PENDING = 'duplo/sites/CREATE_SITE_PENDING';
export const CREATE_SITE_FULFILLED = 'duplo/sites/CREATE_SITE_FULFILLED';
export const CREATE_SITE_FAILED = 'duplo/sites/CREATE_SITE_FAILED';

export const SAVE_SITE_PENDING = 'duplo/sites/SAVE_SITE_PENDING';
export const SAVE_SITE_FULFILLED = 'duplo/sites/SAVE_SITE_FULFILLED';
export const SAVE_SITE_FAILED = 'duplo/sites/SAVE_SITE_FAILED';

export const PUBLISH_SITE_PENDING = 'duplo/sites/PUBLISH_SITE_PENDING';
export const PUBLISH_SITE_FULFILLED = 'duplo/sites/PUBLISH_SITE_FULFILLED';
export const PUBLISH_SITE_FAILED = 'duplo/sites/PUBLISH_SITE_FAILED';

export const UNPUBLISH_SITE_PENDING = 'duplo/sites/UNPUBLISH_SITE_PENDING';
export const UNPUBLISH_SITE_FULFILLED = 'duplo/sites/UNPUBLISH_SITE_FULFILLED';
export const UNPUBLISH_SITE_FAILED = 'duplo/sites/UNPUBLISH_SITE_FAILED';

export const ARCHIVE_SITE_PENDING = 'duplo/sites/ARCHIVE_SITE_PENDING';
export const ARCHIVE_SITE_FULFILLED = 'duplo/sites/ARCHIVE_SITE_FULFILLED';
export const ARCHIVE_SITE_FAILED = 'duplo/sites/ARCHIVE_SITE_FAILED';
export const UPDATE_SITE = 'duplo/sites/UPDATE_SITE';
export const SET_ACTIVE_WIDGET_ID = 'duplo/sites/SET_ACTIVE_WIDGET_ID';
export const UPDATE_PAGE_CONTENT = 'duplo/pages/UPDATE_PAGE_CONTENT';

type NewFont = {
  size: {
    body: {
      base: {
        value: number;
      };
    };
  };
  weight: {
    base: {
      value: string;
    };
  };
  family: {
    base: {
      value: string;
    };
  };
};

export interface SiteSlice extends Site {
  pages: {
    [index: number]: Page;
  };
  prevSiteProvisioningStores?: Array<number>;
  meta: StateMeta;
}

export interface State {
  data: Array<SiteSlice>;
  meta: StateMeta;
  publishMeta: StateMeta;
  saveSiteMeta: StateMeta & { siteId?: number };
  activeWidgetId: string;
  createSiteMeta: StateMeta;
  newSiteId: number;
}

const defaultState: State = {
  meta: {
    pending: false,
    successful: false,
  },
  publishMeta: {
    pending: false,
  },
  saveSiteMeta: {
    pending: false,
  },
  data: [],
  activeWidgetId: 'root',
  createSiteMeta: {
    pending: false,
    successful: false,
  },
  newSiteId: undefined,
};

const withSite = (
  state: State,
  siteId: number,
  cb: (slice: SiteSlice) => SiteSlice
) => {
  return {
    ...state,
    data: state.data.map(site => {
      if (siteId === site.id) {
        return cb(site);
      }
      return site;
    }),
  };
};

const withPage = (
  state: State,
  siteId: number,
  pageId: number,
  cb: (slice: Page) => Page
) => {
  return withSite(state, siteId, site => {
    return {
      ...site,
      pages: {
        ...site.pages,
        [pageId]: cb(site.pages[pageId]),
      },
    };
  });
};

const withCurrentPageVersion = (
  state: State,
  siteId: number,
  pageId: number,
  cb: (slice: PageVersion) => PageVersion
) => {
  return withPage(state, siteId, pageId, page => ({
    ...page,
    current: cb(page.current),
  }));
};

const updateDefaultWidget = (
  state: State,
  siteId: number,
  pageId: number,
  widgetId: string,
  cb: (slice: Widget<any>) => Widget<any>
) => {
  return withCurrentPageVersion(state, siteId, pageId, page => {
    return {
      ...page,
      content: {
        ...page.content,
        [widgetId]: cb(page.content[widgetId]),
      },
    };
  });
};

const withWidget = (
  state: State,
  siteId: number,
  pageId: number,
  widgetId: string,
  cb: (slice: Widget<any>) => Widget<any>
) => {
  return withCurrentPageVersion(state, siteId, pageId, page => {
    return {
      ...page,
      forms: {
        ...page?.forms,
        update: [
          ...(page?.forms?.update || []),
          ...((page.content[widgetId]?.type === 'Form' &&
            !page?.forms?.create?.includes(widgetId) &&
            !page?.forms?.update?.includes(widgetId) && [widgetId]) ||
            []),
        ],
      },
      content: {
        ...page.content,
        [widgetId]: cb(page.content[widgetId]),
      },
    };
  });
};

/**
 * Remove any links to a page within a nav structure
 * @param pageId ID of page being deleted
 * @param items Navigation items
 */
const removePageFromNav = (
  pageId: number,
  items: Array<NavItem>
): Array<NavItem> => {
  return items
    .filter(item => {
      if (item.type === 'page') {
        return item.pageId !== pageId;
      }
      return true;
    })
    .map(item => {
      if (item.type === 'section') {
        return {
          ...item,
          children: removePageFromNav(pageId, item.children),
        };
      }
      return item;
    });
};

// removes children from non-section nav-items
const removeInvalidChildren = (navItems: NavItem[]) => {
  navItems.forEach(item => {
    if (item.type !== 'section' && item.children) {
      item.children = null;
    }
    if (item.children) {
      removeInvalidChildren(item.children);
    }
  });
};

/**
 * Reducer
 */

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_WIDGET_POSITION:
      return withCurrentPageVersion(
        state,
        action.siteId,
        action.pageId,
        page => {
          const headerChildren = page.content['headerSection'].children;
          const rootWidgetIds = page.content.root.children.filter(
            id =>
              id !== action.bannerWidgetId &&
              !headerChildren.includes(id) &&
              id !== 'headerSection'
          );
          const updatedContent = { ...page.content };
          rootWidgetIds.forEach(id => {
            updatedContent[id].config.marginTop =
              (updatedContent[id].config.marginTop || 0) +
              action.height -
              action.oldHeight;
            if (updatedContent[id].children.length && id !== 'footerSection') {
              updatedContent[id].children.forEach(childId => {
                updatedContent[childId].config.marginTop = action.height;
              });
            }
          });
          return {
            ...page,
            content: {
              ...page.content,
              ...updatedContent,
            },
          };
        }
      );
    case CREATE_SITE_PENDING:
      return {
        ...state,
        createSiteMeta: { ...state.createSiteMeta, pending: true },
      };
    case CREATE_SITE_FAILED:
      return {
        ...state,
        createSiteMeta: {
          ...state.createSiteMeta,
          pending: false,
          successful: false,
        },
      };
    case CREATE_SITE_FULFILLED:
      return {
        ...state,
        createSiteMeta: {
          ...state.createSiteMeta,
          pending: false,
          successful: true,
        },
        data: state.data.concat({
          ...(action.site as Site),
          pages: {},
          siteProvisioningStores: [],
          prevSiteProvisioningStores: [],
          meta: {},
        }),
        newSiteId: action.site.id,
      };
    case ADD_CHILD:
      const newWidgetId = action.widgetId || uuidv4();
      let updatedStateAddChild = { ...state };
      withCurrentPageVersion(state, action.siteId, action.pageId, page => {
        const rootWidgetIds = page.content.root.children;
        let bannerWidgetId;
        rootWidgetIds.forEach(id => {
          if (page.content[id].type === widgetTypes.Banner) {
            bannerWidgetId = id;
          }
        });
        const headerSectionWidgetConfig = page.content['headerSection']?.config;
        const footerSectionWidgetConfig = page.content['footerSection']?.config;
        const footerSectionTopY = footerSectionWidgetConfig
          ? footerSectionWidgetConfig.yLocation
          : 0;
        const headerSectionBottomY = headerSectionWidgetConfig
          ? headerSectionWidgetConfig.yLocation +
            headerSectionWidgetConfig.height
          : 0;
        const currentWidgetConfig = {
          visible: true,
          ...action.initialConfig,
          parentId: action.parentWidgetId,
        };
        const currentWidgetTopY = currentWidgetConfig.yLocation;
        const currentWidgetBottomY =
          currentWidgetConfig.yLocation + currentWidgetConfig.height;
        const yLocationDiffWithFooter =
          currentWidgetConfig.yLocation - footerSectionTopY;
        const widgetListNotToAddInDefaultSections = [
          widgetTypes.AccordionWidget,
          widgetTypes.Banner,
          widgetTypes.SlideShow,
          widgetTypes.Tabs,
          widgetTypes.Form,
          widgetTypes.Card,
          widgetTypes.Application,
        ];
        if (
          !widgetListNotToAddInDefaultSections.includes(action.widgetType) &&
          page.content[action.parentWidgetId].defaultWidget &&
          headerSectionWidgetConfig &&
          footerSectionWidgetConfig &&
          (currentWidgetTopY < headerSectionBottomY ||
            currentWidgetBottomY > footerSectionTopY)
        ) {
          const sitePages = state.data.find(site => site.id === action.siteId)
            .pages;
          Object.keys(sitePages).forEach(index => {
            let yDifferenceToUse = yLocationDiffWithFooter;
            const indexPageId = parseInt(index, 10);
            const indexPageRootWidgetIds =
              sitePages[indexPageId].current.content.root.children;
            let indexPageBannerWidgetId;
            indexPageRootWidgetIds.forEach(id => {
              if (
                sitePages[indexPageId].current.content[id].type ===
                widgetTypes.Banner
              ) {
                indexPageBannerWidgetId = id;
              }
            });
            if (bannerWidgetId) {
              if (indexPageId !== action.pageId) {
                if (indexPageBannerWidgetId) {
                  yDifferenceToUse =
                    yLocationDiffWithFooter +
                    sitePages[indexPageId].current.content[
                      indexPageBannerWidgetId
                    ].config.height -
                    sitePages[action.pageId].current.content[bannerWidgetId]
                      .config.height;
                } else {
                  yDifferenceToUse =
                    yLocationDiffWithFooter -
                    sitePages[action.pageId].current.content[bannerWidgetId]
                      .config.height;
                }
              }
            } else {
              if (indexPageBannerWidgetId) {
                yDifferenceToUse =
                  yLocationDiffWithFooter +
                  sitePages[indexPageId].current.content[
                    indexPageBannerWidgetId
                  ].config.height;
              }
            }
            const footerYForPage =
              sitePages[index].current.content['footerSection'].config
                .yLocation;
            const updatedConfigForChildWidget =
              currentWidgetBottomY > footerSectionTopY
                ? {
                    ...currentWidgetConfig,
                    yLocation: footerYForPage + yDifferenceToUse,
                  }
                : currentWidgetConfig;
            updatedStateAddChild = withCurrentPageVersion(
              updatedStateAddChild,
              action.siteId,
              parseInt(index, 10),
              indexPage => {
                return {
                  ...indexPage,
                  content: {
                    ...indexPage.content,
                    [action.parentWidgetId]: {
                      ...indexPage.content[action.parentWidgetId],
                      children: [
                        ...indexPage.content[
                          action.parentWidgetId
                        ].children.slice(0, action.location),
                        newWidgetId,
                        ...indexPage.content[
                          action.parentWidgetId
                        ].children.slice(action.location),
                      ],
                    },
                    [newWidgetId]: {
                      id: newWidgetId,
                      type: action.widgetType,
                      config: updatedConfigForChildWidget,
                      children: [],
                      defaultWidget: true,
                      tabIndex:
                        action.tabIndex !== undefined ? action.tabIndex : -1,
                    },
                  },
                };
              }
            );
          });
        } else
          updatedStateAddChild = withCurrentPageVersion(
            updatedStateAddChild,
            action.siteId,
            action.pageId,
            page => {
              return {
                ...page,
                forms: {
                  ...page?.forms,
                  create:
                    action.widgetType === widgetTypes.Form
                      ? [...(page?.forms?.create || []), newWidgetId]
                      : [...(page?.forms?.create || [])],
                },
                content: {
                  ...page.content,
                  [action.parentWidgetId]: {
                    ...page.content[action.parentWidgetId],
                    children: [
                      ...page.content[action.parentWidgetId].children.slice(
                        0,
                        action.location
                      ),
                      newWidgetId,
                      ...page.content[action.parentWidgetId].children.slice(
                        action.location
                      ),
                    ],
                  },
                  [newWidgetId]: {
                    id: newWidgetId,
                    type: action.widgetType,
                    config: action.initialConfig,
                    children: [],
                    tabIndex:
                      action.tabIndex !== undefined ? action.tabIndex : -1,
                  },
                },
              };
            }
          );
        return {
          ...page,
          content: {
            ...page.content,
            [action.parentWidgetId]: {
              ...page.content[action.parentWidgetId],
              children: [
                ...page.content[action.parentWidgetId].children.slice(
                  0,
                  action.location
                ),
                newWidgetId,
                ...page.content[action.parentWidgetId].children.slice(
                  action.location
                ),
              ],
            },
            [newWidgetId]: {
              id: newWidgetId,
              type: action.widgetType,
              config: action.initialConfig,
              children: [],
              tabIndex: action.tabIndex !== undefined ? action.tabIndex : -1,
            },
          },
        };
      });
      return updatedStateAddChild;
    case ADD_SIBLING:
      const newSiblingId = action.newWidgetId || uuidv4();
      let updatedStateAddSibling = { ...state };
      withCurrentPageVersion(state, action.siteId, action.pageId, page => {
        let parentId: string = Object.keys(page.content).find((key: string) => {
          return page.content[key].children.includes(action.siblingWidgetId);
        });
        let location: number =
          page.content[parentId].children?.indexOf(action.siblingWidgetId) + 1;
        if (page.content[action.siblingWidgetId].defaultWidget) {
          const sitePages = state.data.find(site => site.id === action.siteId)
            .pages;
          Object.keys(sitePages).forEach(index => {
            //get sibling widget from page
            updatedStateAddSibling = withCurrentPageVersion(
              updatedStateAddSibling,
              action.siteId,
              parseInt(index, 10),
              indexPage => {
                parentId = Object.keys(indexPage.content).find(
                  (key: string) => {
                    return indexPage.content[key].children.includes(
                      action.siblingWidgetId
                    );
                  }
                );
                const siblingWidget = indexPage.content[action.siblingWidgetId];
                location =
                  indexPage.content[parentId].children?.indexOf(
                    action.siblingWidgetId
                  ) + 1;
                return {
                  ...indexPage,
                  content: {
                    ...indexPage.content,
                    [parentId]: {
                      ...indexPage.content[parentId],
                      children: [
                        ...indexPage.content[parentId].children.slice(
                          0,
                          location
                        ),
                        newSiblingId,
                        ...indexPage.content[parentId].children.slice(location),
                      ],
                    },
                    [newSiblingId]: {
                      id: newSiblingId,
                      type: action.widgetType,
                      config: {
                        ...action.config,
                        xLocation: siblingWidget.config.xLocation + 10,
                        yLocation: siblingWidget.config.yLocation + 10,
                        parentId: parentId,
                      },
                      defaultWidget: true,
                      children: action.children || [],
                      tabIndex:
                        action.tabIndex !== undefined ? action.tabIndex : -1,
                    },
                  },
                };
              }
            );
            // }
          });
        } else
          updatedStateAddSibling = withCurrentPageVersion(
            updatedStateAddSibling,
            action.siteId,
            action.pageId,
            page => {
              return {
                ...page,
                content: {
                  ...page.content,
                  [parentId]: {
                    ...page.content[parentId],
                    children: [
                      ...page.content[parentId].children.slice(0, location),
                      newSiblingId,
                      ...page.content[parentId].children.slice(location),
                    ],
                  },
                  [newSiblingId]: {
                    id: newSiblingId,
                    type: action.widgetType,
                    config: {
                      ...action.config,
                      xLocation:
                        page.content[action.siblingWidgetId].config.xLocation +
                        10,
                      yLocation:
                        page.content[action.siblingWidgetId].config.yLocation +
                        10,
                    },
                    children: action.children || [],
                    tabIndex:
                      action.tabIndex !== undefined ? action.tabIndex : -1,
                  },
                },
              };
            }
          );
        return {
          ...page,
          forms: {
            ...page?.forms,
            create:
              action.widgetType === 'Form'
                ? [...(page?.forms?.create || []), newSiblingId]
                : [...(page?.forms?.create || [])],
          },
          content: {
            ...page.content,
            [parentId]: {
              ...page.content[parentId],
              children: [
                ...page.content[parentId].children.slice(0, location),
                newSiblingId,
                ...page.content[parentId].children.slice(location),
              ],
            },
            [newSiblingId]: {
              id: newSiblingId,
              type: action.widgetType,
              config: action.config,
              children: action.children || [],
              tabIndex: action.tabIndex !== undefined ? action.tabIndex : -1,
            },
          },
        };
      });
      return updatedStateAddSibling;
    case ADOPT_CHILD:
      return withCurrentPageVersion(
        state,
        action.siteId,
        action.pageId,
        page => ({
          ...page,
          forms: {
            ...page?.forms,
            create:
              action.widgetType === 'Form'
                ? [...(page?.forms?.create || []), action.childWidgetId]
                : [...(page?.forms?.create || [])],
          },
          content: Object.keys(page.content).reduce((acc, key) => {
            const children = page.content[key].children.filter(
              id => id !== action.childWidgetId
            );
            acc[key] = {
              ...page.content[key],
              children:
                action.parentWidgetId === key
                  ? [
                      ...children.slice(0, action.location),
                      action.childWidgetId,
                      ...children.slice(action.location),
                    ]
                  : children,
            };
            return acc;
          }, {} as any),
        })
      );
    case UPDATE_WIDGET_CONFIG:
      let updatedStateUpdateWidgetConfig = { ...state };
      if (action.widgetId !== 'root') {
        if (action.defaultWidget) {
          const sitePages = state.data.find(site => site.id === action.siteId)
            .pages;
          const currentPage = sitePages[action.pageId].current;
          const updatedConfigXLocation = action.config.xLocation;
          const updatedConfigYLocation = action.config.yLocation;
          const oldConfigXLocation =
            currentPage.content[action.widgetId].config.xLocation;
          const oldConfigYLocation =
            currentPage.content[action.widgetId].config.yLocation;
          const changeInLocation =
            oldConfigXLocation !== updatedConfigXLocation ||
            oldConfigYLocation !== updatedConfigYLocation;
          if (action.widgetId === 'footerSection' && changeInLocation) {
            updatedStateUpdateWidgetConfig = updateDefaultWidget(
              updatedStateUpdateWidgetConfig,
              action.siteId,
              action.pageId,
              action.widgetId,
              widget => ({
                ...widget,
                config: action.config,
              })
            );
          } else if (action.widgetId === 'headerSection' && changeInLocation) {
            return updatedStateUpdateWidgetConfig;
          } else {
            Object.keys(sitePages).forEach(index => {
              let xLocationToUse = updatedConfigXLocation;
              let yLocationToUse = updatedConfigYLocation;
              if (parseInt(index, 10) !== action.pageId) {
                const xDifference = updatedConfigXLocation - oldConfigXLocation;
                const yDifference = updatedConfigYLocation - oldConfigYLocation;
                xLocationToUse =
                  sitePages[index].current.content[action.widgetId].config
                    .xLocation + xDifference;
                yLocationToUse =
                  sitePages[index].current.content[action.widgetId].config
                    .yLocation + yDifference;
              }
              const updatedConfig = {
                ...action.config,
                xLocation: xLocationToUse,
                yLocation: yLocationToUse,
              };
              // if (parseInt(index, 10) !== action.pageId) {
              updatedStateUpdateWidgetConfig = updateDefaultWidget(
                updatedStateUpdateWidgetConfig,
                action.siteId,
                parseInt(index, 10),
                action.widgetId,
                widget => ({
                  ...widget,
                  config: updatedConfig,
                })
              );
              // }
            });
          }
          return updatedStateUpdateWidgetConfig;
        }
        return withWidget(
          updatedStateUpdateWidgetConfig,
          action.siteId,
          action.pageId,
          action.widgetId,
          widget => ({
            ...widget,
            config: action.config,
          })
        );
      }
      return updatedStateUpdateWidgetConfig;
    case UPDATE_CURRENT_PAGE_WIDGET_CONFIG:
      if (action.widgetId !== 'root') {
        return withWidget(
          state,
          action.siteId,
          action.pageId,
          action.widgetId,
          widget => ({
            ...widget,
            config: action.config,
            defaultWidget: false,
          })
        );
      }
      return state;
    case REMOVE_WIDGET:
      let updatedStateRemoveWidget = { ...state };
      withCurrentPageVersion(state, action.siteId, action.pageId, page => {
        const sitePages = state.data.find(site => site.id === action.siteId)
          .pages;
        Object.keys(sitePages).forEach(index => {
          // if (parseInt(index, 10) !== action.pageId) {
          updatedStateRemoveWidget = withCurrentPageVersion(
            updatedStateRemoveWidget,
            action.siteId,
            parseInt(index, 10),
            indexPage => {
              return {
                ...indexPage,
                content: Object.keys(indexPage.content)
                  .filter(key => key !== action.widgetId)
                  .reduce((acc, key) => {
                    acc[key] = {
                      ...indexPage.content[key],
                      children: indexPage.content[key].children.filter(
                        id => id !== action.widgetId
                      ),
                    };
                    return acc;
                  }, {} as any),
              };
            }
          );
          // }
        });
        return {
          ...page,
          forms: {
            delete: [
              ...(page?.forms?.delete || []),
              ...(!page?.forms?.create?.filter(
                widgetId => widgetId === action.widgetId
              ).length
                ? [action.widgetId]
                : []),
            ],
            create: page?.forms?.create?.filter(
              widgetId => widgetId !== action.widgetId
            ) || [...(page?.forms?.create || [])],
            update: page?.forms?.update?.filter(
              widgetId => widgetId !== action.widgetId
            ) || [...(page?.forms?.update || [])],
          },
          content: Object.keys(page.content)
            .filter(key => key !== action.widgetId)
            .reduce((acc, key) => {
              acc[key] = {
                ...page.content[key],
                children: page.content[key].children.filter(
                  id => id !== action.widgetId
                ),
              };
              return acc;
            }, {} as any),
        };
      });
      return updatedStateRemoveWidget;
    case CHANGE_WIDGET_VISIBLE: {
      return withCurrentPageVersion(
        state,
        action.siteId,
        action.pageId,
        page => {
          const isValidIndexValue = (value: any): boolean =>
            (typeof value === 'number' && !isNaN(value)) ||
            typeof value === 'string';
          const updatedContent = { ...page.content };
          // Check if the widget exists in the page content
          if (updatedContent[action.widgetId]) {
            if (action.currentTabIndex !== undefined) {
              updatedContent[action.parentWidgetId].children.forEach(
                (childId: string) => {
                  updatedContent[childId].config.visible =
                    updatedContent[childId].tabIndex === action.currentTabIndex;
                  if (updatedContent[childId].children.length) {
                    updatedContent[childId].children.forEach(
                      (child: string) => {
                        updatedContent[child].config.visible =
                          updatedContent[childId].tabIndex ===
                          updatedContent[action.parentWidgetId].config
                            .currentTabIndex;
                        // If it's a child inside a Tab, SlideShow or Accordion widget, check if the currentTabIndex or
                        // currentSlideIndex of page.content[childId] === child's tabIndex
                        if (
                          isValidIndexValue(
                            updatedContent[childId].config.currentSlideIndex
                          ) ||
                          isValidIndexValue(
                            updatedContent[childId].config.currentTabIndex
                          )
                        ) {
                          updatedContent[child].config.visible =
                            updatedContent[childId].tabIndex ===
                              updatedContent[action.parentWidgetId].config
                                .currentTabIndex &&
                            updatedContent[child].tabIndex ===
                              updatedContent[childId].config.currentSlideIndex;
                        }
                      }
                    );
                  }
                }
              );
            } else {
              // This logic is for NavWidget (vertical) which has a different pattern than other widgets (Accordion, Tabs, Slideshow etc.)
              updatedContent[action.parentWidgetId].children.forEach(
                (childId: string) => {
                  if (childId === action.widgetId) {
                    updatedContent[childId].children.forEach(
                      (contentChild: string) => {
                        updatedContent[contentChild].config.visible = true;
                        if (updatedContent[contentChild].children.length) {
                          updatedContent[contentChild].children.forEach(
                            (child: string) => {
                              const {
                                currentSlideIndex,
                                currentTabIndex,
                              } = updatedContent[contentChild].config;
                              if (
                                (isValidIndexValue(currentSlideIndex) ||
                                  isValidIndexValue(currentTabIndex)) &&
                                (updatedContent[child].tabIndex ===
                                  currentSlideIndex ||
                                  updatedContent[child].tabIndex ===
                                    currentTabIndex)
                              ) {
                                updatedContent[child].config.visible = true;
                              }
                            }
                          );
                        }
                      }
                    );
                    updatedContent[childId].config.visible = true;
                  } else if (
                    !updatedContent[action.widgetId].children.includes(childId)
                  ) {
                    updatedContent[childId].children.forEach(
                      (contentChild: string) => {
                        // Update the widget's children visible values
                        updatedContent[contentChild].config.visible = false;
                      }
                    );
                    // Update the widget's visible value
                    updatedContent[childId].config.visible = false;
                  }
                }
              );
            }
          }
          // Return the updated page
          return {
            ...page,
            content: {
              ...page.content,
              ...updatedContent,
            },
          };
        }
      );
    }
    case UPDATE_PAGE_CONTENT:
      return withSite(state, action.siteId, site => {
        return {
          ...site,
          pages: {
            ...site.pages,
            [action.pageId]: action.page.page,
          },
        };
      });
    case FETCH_SITES_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_SITES_FULFILLED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
        },
        data: (action.sites as Array<Site>).map(site => ({
          ...site,
          pages: {},
          meta: {},
          prevSiteProvisioningStores: site.siteProvisioningStores,
        })),
      };
    case FETCH_SITES_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
        },
      };
    case FETCH_SITES_BY_UUID_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_SITES_BY_UUID_FULFILLED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
        },
        data: (action.sites as Array<Site>).map(site => ({
          ...site,
          tenancy: {
            tenantId: site['tenantId'],
          },
          pages: {},
          meta: {},
          prevSiteProvisioningStores: site.siteProvisioningStores,
        })),
      };
    case FETCH_SITES_BY_UUID_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
        },
      };
    case FETCH_SITE_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_SITE_FULFILLED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
        },
        data: state.data.map(site => {
          if (site.id === action.site.id) {
            return {
              ...action.site,
              prevSiteProvisioningStores: site.siteProvisioningStores,
            };
          }
          return site;
        }),
      };
    case FETCH_SITE_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
        },
      };
    case FETCH_PAGES_PENDING:
      return withSite(state, action.siteId, site => ({
        ...site,
        meta: {
          ...site.meta,
          pending: true,
        },
      }));
    case FETCH_PAGES_FULFILLED:
      return withSite(state, action.siteId, site => ({
        ...site,
        pages: action.pages.reduce(
          (acc: { [index: string]: Page }, page: Page) => {
            const { content } = page.current;
            if (content === undefined || content === null) {
              //TODO: ensure template index is correct
              page.current.content = templates[0].widgets;
            }
            acc[page.id] = page;
            return acc;
          },
          {}
        ),
        meta: {
          ...site.meta,
          pending: false,
          successful: true,
        },
      }));
    case FETCH_PAGES_FAILED:
      return withSite(state, action.siteId, site => ({
        ...site,
        meta: {
          ...site.meta,
          pending: false,
          successful: false,
        },
      }));
    case CREATE_PAGE_FULFILLED:
      return withSite(state, action.siteId, site => ({
        ...site,
        pages: {
          ...site.pages,
          [action.page.id]: action.page,
        },
      }));
    case SAVE_PAGE_FULFILLED:
      return withPage(state, action.siteId, action.pageId, page => ({
        ...page,
        current: action.newVersion,
      }));
    // save all pages in a site
    case SAVE_ALL_PAGE_FULFILLED:
      return withSite(state, action.siteId, site => ({
        ...site,
        pages: Object.keys(site.pages).reduce((acc, key) => {
          acc[key] = {
            ...site.pages[key],
            current: action.newVersions[key],
          };
          return acc;
        }, {}),
      }));
    case REMOVE_PAGE_FULFILLED:
      return withSite(state, action.siteId, site => ({
        ...site,
        pages: Object.keys(site.pages)
          .map(key => parseInt(key, 10))
          .filter(key => key !== action.pageId)
          .reduce((acc, key) => {
            acc[key] = site.pages[key];
            return acc;
          }, {}),
      }));
    case SAVE_SITE_PENDING:
      return {
        ...state,
        saveSiteMeta: {
          pending: true,
          siteId: action.siteId,
        },
      };
    case SAVE_SITE_FULFILLED:
      return {
        ...state,
        data: state.data.map(site => {
          if (site.id === action.siteId) {
            return {
              ...site,
              ...action.site,
            };
          }
          return site;
        }),
        saveSiteMeta: {
          pending: false,
          successful: true,
          // populated if save was partially successful but an error occured
          error: action.error,
        },
      };
    case SAVE_SITE_FAILED:
      return {
        ...state,
        saveSiteMeta: {
          pending: false,
          successful: false,
          error: action.error,
          siteId: action.siteId,
        },
      };
    case PUBLISH_SITE_FAILED:
      return {
        ...state,
        publishMeta: {
          ...state.publishMeta,
          pending: false,
          successful: false,
        },
      };
    case PUBLISH_SITE_FULFILLED:
      return {
        ...state,
        publishMeta: { ...state.publishMeta, pending: false, successful: true },
        data: state.data.map(site => {
          if (site.id === action.siteId) {
            return {
              ...site,
              published: true,
            };
          }
          return site;
        }),
      };
    case PUBLISH_SITE_PENDING:
      return {
        ...state,
        publishMeta: { ...state.publishMeta, pending: true },
      };
    case UNPUBLISH_SITE_FAILED:
      return {
        ...state,
        publishMeta: {
          ...state.publishMeta,
          pending: false,
          successful: false,
        },
      };
    case UNPUBLISH_SITE_FULFILLED:
      return {
        ...state,
        publishMeta: { ...state.publishMeta, pending: false, successful: true },
        data: state.data.map(site => {
          if (site.id === action.siteId) {
            return {
              ...site,
              published: false,
            };
          }
          return site;
        }),
      };
    case UNPUBLISH_SITE_PENDING:
      return {
        ...state,
        publishMeta: { ...state.publishMeta, pending: true },
      };
    case ARCHIVE_SITE_FULFILLED:
      return withSite(state, action.siteId, site => ({
        ...site,
        archived: true,
      }));
    case UPDATE_SITE:
      return withSite(state, action.siteId, site => {
        return {
          ...site,
          ...action.site,
          idpHint: action.site?.idpHint || '',
        };
      });
    case SET_ACTIVE_WIDGET_ID:
      return {
        ...state,
        activeWidgetId: action.widgetId,
      };
    case SET_HOME_PAGE:
      return withSite(state, action.siteId, site => ({
        ...site,
        homepageId: action.pageId,
      }));
    case UPDATE_CURRENT_PAGE:
      return withPage(
        state,
        action.siteId,
        action.currentPage.pageId,
        page => ({
          ...page,
          current: action.currentPage,
        })
      );
    case UPDATE_PAGE_DEFAULT_WIDGET:
      let updatedStateUpdatePageDefaultWidget = { ...state };
      const sitePages = state.data.find(site => site.id === action.siteId)
        .pages;
      Object.keys(sitePages).forEach(index => {
        if (parseInt(index, 10) !== action.pageId) {
          const widgetToUpdateConfig =
            sitePages[index].current.content[action.widgetId].config;
          const currentXLocation = widgetToUpdateConfig.xLocation;
          const currentYLocation = widgetToUpdateConfig.yLocation;
          const updatedConfig = {
            ...widgetToUpdateConfig,
            height: action.config.height,
            width: action.config.width,
            xLocation: currentXLocation + action.xDifference,
            yLocation: currentYLocation + action.yDifference,
          };
          updatedStateUpdatePageDefaultWidget = updateDefaultWidget(
            updatedStateUpdatePageDefaultWidget,
            action.siteId,
            parseInt(index, 10),
            action.widgetId,
            widget => ({
              ...widget,
              config: updatedConfig,
            })
          );
        }
      });
      return updatedStateUpdatePageDefaultWidget;
    case REMOVE_WIDGET_DEFAULT:
      let updatedStateRemoveWidgetDefault = { ...state };
      withCurrentPageVersion(state, action.siteId, action.pageId, page => {
        const sitePages = state.data.find(site => site.id === action.siteId)
          .pages;
        Object.keys(sitePages).forEach(index => {
          if (parseInt(index, 10) !== action.pageId) {
            updatedStateRemoveWidgetDefault = withCurrentPageVersion(
              updatedStateRemoveWidgetDefault,
              action.siteId,
              parseInt(index, 10),
              indexPage => {
                return {
                  ...indexPage,
                  content: Object.keys(indexPage.content)
                    .filter(key => key !== action.widgetId)
                    .reduce((acc, key) => {
                      if (indexPage.content[key].children !== undefined) {
                        acc[key] = {
                          ...indexPage.content[key],
                          children: indexPage.content[key].children.filter(
                            id => id !== action.widgetId
                          ),
                        };
                        return acc;
                      }
                      return acc;
                    }, {} as any),
                };
              }
            );
            // }
          }
        });
        return {
          ...page,
          forms: {
            delete: [
              ...(page?.forms?.delete || []),
              ...(!page?.forms?.create?.filter(
                widgetId => widgetId === action.widgetId
              ).length
                ? [action.widgetId]
                : []),
            ],
            create: page?.forms?.create?.filter(
              widgetId => widgetId !== action.widgetId
            ) || [...(page?.forms?.create || [])],
            update: page?.forms?.update?.filter(
              widgetId => widgetId !== action.widgetId
            ) || [...(page?.forms?.update || [])],
          },
          content: Object.keys(page.content)
            .filter(key => key !== action.widgetId)
            .reduce((acc, key) => {
              acc[key] = {
                ...page.content[key],
                children: page.content[key].children.filter(
                  id => id !== action.widgetId
                ),
              };
              return acc;
            }, {} as any),
        };
      });
      return updatedStateRemoveWidgetDefault;

    default:
      return state;
  }
};

export default reducer;

/**
 * Action creators
 */

export const addChild: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  parentWidgetId: string,
  widgetType: string | FieldType,
  initialConfig: any,
  location: number,
  widgetId?: string,
  tabIndex?: number
) => {
  return {
    type: ADD_CHILD,
    siteId,
    pageId,
    parentWidgetId,
    widgetType,
    initialConfig,
    location,
    widgetId,
    tabIndex,
  };
};

export const updateWidgetPosition: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  bannerWidgetId: string,
  height: number,
  oldHeight: number
) => {
  return {
    type: UPDATE_WIDGET_POSITION,
    siteId,
    pageId,
    bannerWidgetId,
    height,
    oldHeight,
  };
};

export const addSibling: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  siblingWidgetId: string,
  widgetType: string,
  config: any,
  newWidgetId?: string,
  children?: string[],
  tabIndex?: number
) => {
  return {
    type: ADD_SIBLING,
    siteId,
    pageId,
    siblingWidgetId,
    widgetType,
    config,
    newWidgetId,
    children,
    tabIndex,
  };
};

export const adoptChild: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  parentWidgetId: string,
  childWidgetId: string,
  location: number
) => {
  return {
    type: ADOPT_CHILD,
    siteId,
    pageId,
    parentWidgetId,
    childWidgetId,
    location,
  };
};

export const updateWidgetConfig: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  widgetId: string,
  config: any,
  defaultWidget: boolean
) => {
  return {
    type: UPDATE_WIDGET_CONFIG,
    siteId,
    pageId,
    widgetId,
    config,
    defaultWidget,
  };
};

export const updateCurrentPageWidgetConfig: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  widgetId: string,
  config: any
) => {
  return {
    type: UPDATE_CURRENT_PAGE_WIDGET_CONFIG,
    siteId,
    pageId,
    widgetId,
    config,
  };
};

export const updateDefaultWidgetConfig: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  widgetId: string,
  config: any
) => {
  return {
    type: UPDATE_DEFAULT_WIDGET_CONFIG,
    siteId,
    pageId,
    widgetId,
    config,
  };
};

export const removeWidget: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  widgetId: string
) => {
  return { type: REMOVE_WIDGET, siteId, pageId, widgetId };
};

export const removeDefaultWidgets: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  widgetId: string
) => {
  return { type: REMOVE_WIDGET_DEFAULT, siteId, pageId, widgetId };
};

export const updatePageContent: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  page: any
) => {
  return { type: UPDATE_PAGE_CONTENT, siteId, pageId, page };
};

const fetchPagesPending: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: FETCH_PAGES_PENDING, siteId };
};

const fetchPagesFulfilled: ActionCreator<AnyAction> = (
  siteId: number,
  pages: Array<Page>
) => {
  return { type: FETCH_PAGES_FULFILLED, siteId, pages };
};

const fetchPagesFailed: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: FETCH_PAGES_FAILED, siteId };
};

export const createPagePending: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: CREATE_PAGE_PENDING, siteId };
};

const createPageFulfilled: ActionCreator<AnyAction> = (
  siteId: number,
  page: Page
) => {
  return { type: CREATE_PAGE_FULFILLED, siteId, page };
};

export const createPageFailed: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: CREATE_PAGE_FAILED, siteId };
};

export const savePagePending: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number
) => {
  return { type: SAVE_PAGE_PENDING, siteId, pageId };
};

export const savePageFulfilled: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  newVersion: PageVersion
) => {
  return { type: SAVE_PAGE_FULFILLED, siteId, pageId, newVersion };
};

export const savePageFailed: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number
) => {
  return { type: SAVE_PAGE_FAILED, siteId, pageId };
};

export const saveAllPagePending: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: SAVE_ALL_PAGE_PENDING, siteId };
};

export const saveAllPageFulfilled: ActionCreator<AnyAction> = (
  siteId: number,
  newVersions: Array<PageVersion>
) => {
  return { type: SAVE_ALL_PAGE_FULFILLED, siteId, newVersions };
};

export const saveAllPageFailed: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: SAVE_ALL_PAGE_FAILED, siteId };
};

export const removePagePending: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number
) => {
  return { type: REMOVE_PAGE_PENDING, siteId, pageId };
};

export const removePageFulfilled: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number
) => {
  return { type: REMOVE_PAGE_FULFILLED, siteId, pageId };
};

export const removePageFailed: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number
) => {
  return { type: REMOVE_PAGE_FAILED, siteId, pageId };
};

export const setHomePage: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number
) => {
  return { type: SET_HOME_PAGE, siteId, pageId };
};

const fetchSitesPending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_SITES_PENDING };
};

const fetchSitesFulfilled: ActionCreator<AnyAction> = (sites: Array<Site>) => {
  return { type: FETCH_SITES_FULFILLED, sites };
};

const fetchSitesFailed: ActionCreator<AnyAction> = () => {
  return { type: FETCH_SITES_FAILED };
};

const fetchSitesByUuidPending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_SITES_BY_UUID_PENDING };
};

const fetchSitesByUuidFulfilled: ActionCreator<AnyAction> = (
  sites: Array<Site>
) => {
  return { type: FETCH_SITES_BY_UUID_FULFILLED, sites };
};

const fetchSitesByUuidFailed: ActionCreator<AnyAction> = () => {
  return { type: FETCH_SITES_BY_UUID_FAILED };
};

const fetchSitePending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_SITE_PENDING };
};

const fetchSiteFulfilled: ActionCreator<AnyAction> = (site: Site) => {
  return { type: FETCH_SITE_FULFILLED, site };
};

const fetchSiteFailed: ActionCreator<AnyAction> = () => {
  return { type: FETCH_SITE_FAILED };
};

export const createSitePending: ActionCreator<AnyAction> = () => {
  return { type: CREATE_SITE_PENDING };
};

export const createSiteFulfilled: ActionCreator<AnyAction> = (site: Site) => {
  return { type: CREATE_SITE_FULFILLED, site };
};

export const createSiteFailed: ActionCreator<AnyAction> = () => {
  return { type: CREATE_SITE_FAILED };
};

export const saveSitePending: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: SAVE_SITE_PENDING, siteId };
};

export const saveSiteFulfilled: ActionCreator<AnyAction> = (
  siteId: number,
  site: Site,
  error?: string
) => {
  return { type: SAVE_SITE_FULFILLED, siteId, site, error };
};

export const saveSiteFailed: ActionCreator<AnyAction> = (
  siteId: number,
  error?: string
) => {
  return { type: SAVE_SITE_FAILED, siteId, error };
};

export const publishSitePending: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: PUBLISH_SITE_PENDING, siteId };
};

export const publishSiteFulfilled: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: PUBLISH_SITE_FULFILLED, siteId };
};

export const publishSiteFailed: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: PUBLISH_SITE_FAILED, siteId };
};

export const unPublishSitePending: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: UNPUBLISH_SITE_PENDING, siteId };
};

export const unPublishSiteFulfilled: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: UNPUBLISH_SITE_FULFILLED, siteId };
};

export const unPublishSiteFailed: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: UNPUBLISH_SITE_FAILED, siteId };
};

export const archiveSitePending: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: ARCHIVE_SITE_PENDING, siteId };
};

export const archiveSiteFulfilled: ActionCreator<AnyAction> = (
  siteId: number
) => {
  return { type: ARCHIVE_SITE_FULFILLED, siteId };
};

export const archiveSiteFailed: ActionCreator<AnyAction> = (siteId: number) => {
  return { type: ARCHIVE_SITE_FAILED, siteId };
};

export const changeWidgetVisible: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  parentWidgetId: string,
  widgetId: string,
  visible: boolean,
  currentTabIndex?: number | string
) => {
  return {
    type: CHANGE_WIDGET_VISIBLE,
    siteId,
    pageId,
    parentWidgetId,
    widgetId,
    visible,
    currentTabIndex,
  };
};

export const updateSite: ActionCreator<AnyAction> = (
  siteId: number,
  site: Site
) => {
  return { type: UPDATE_SITE, siteId, site };
};

export const setActiveWidgetId: ActionCreator<AnyAction> = (
  widgetId: string
) => {
  return { type: SET_ACTIVE_WIDGET_ID, widgetId };
};

/**
 * Side effects
 */

// TODO: Consider moving toasts out of state code

export const fetchSites: ThunkActionCreator = () => dispatch => {
  dispatch(fetchSitesPending());
  doFetchSites()
    .then(sites => {
      dispatch(fetchSitesFulfilled(sites));
    })
    .catch(_ => {
      dispatch(fetchSitesFailed());
    });
};

export const fetchSitesByUuid: ThunkActionCreator = () => dispatch => {
  dispatch(fetchSitesByUuidPending());
  doFetchSitesByUuid()
    .then(sites => {
      dispatch(fetchSitesByUuidFulfilled(sites));
    })
    .catch(_ => {
      dispatch(fetchSitesByUuidFailed());
    });
};

export const fetchSite: ThunkActionCreator = (
  siteId: number,
  openDialog: boolean
) => dispatch => {
  dispatch(fetchSitePending());
  doFetchSite(siteId)
    .then(site => {
      dispatch(fetchSiteFulfilled(site));
      if (openDialog) {
        if (site.apps.length === 0) {
          dispatch(
            openSiteListDialog(SiteListDialogType.DELETE, site.id, site.name)
          );
        } else {
          dispatch(
            openSiteListDialog(
              SiteListDialogType.CANNOT_DELETE,
              site.id,
              site.name
            )
          );
        }
      }
    })
    .catch(_ => {
      dispatch(fetchSiteFailed());
    });
};

/**
 * Create a site. Value of domain will concatenated with base domain.
 */

export const createSite: ThunkActionCreator = (
  site: Partial<Site>,
  template
) => dispatch => {
  dispatch(createSitePending());
  doCreateSite({
    ...site,
    idpHint: site.idpHint ? site.idpHint : '',
    domain: `${site.domain}.${site.domainExtension}`,
    clientId: `${site.domain}`,
  })
    .then(site => {
      doFetchPages(site.id).then(pages => {
        dispatch(createSiteFulfilled(site));
        //fetch newly created page to update with template
        const pageToUpdate = pages.find(
          page => page.id === site.horizonhomepageId
        );
        const updatedPage: Page = {
          ...pageToUpdate,
          current: {
            ...pageToUpdate.current,
            content: template,
          },
        };
        dispatch(closeCreateSiteDialog());
        toast('Site created', { type: 'success', theme: 'colored' });
        dispatch(updatePageContent(site.id, pageToUpdate.id, updatedPage));
        dispatch(savePage(site.id, pageToUpdate.id, updatedPage.current));
      });
    })
    .catch(e => {
      dispatch(createSiteFailed());
      if (e.response.status === 400) {
        toast('Tenancy is  already associated with a different site', {
          type: 'error',
          theme: 'colored',
        });
      } else if (e.response.status >= 500) {
        toast('Unable to associate site with tenancy', {
          type: 'error',
          theme: 'colored',
        });
      } else {
        toast(e.message, { type: 'error', theme: 'colored' });
      }
    });
};

export const saveSite: AsyncThunkActionCreator<Promise<Site>> = (
  siteId: number
) => (dispatch: any, getState: any): Promise<Site> => {
  const site = selectSite(siteId)(getState());
  const promises: Array<Promise<Site>> = [];
  const errors: any = {
    removeStore: { isError: false, message: '' },
    addStore: { isError: false, message: '' },
    saveSite: { isError: false, message: '' },
  };

  const { siteProvisioningStores, prevSiteProvisioningStores } = site;
  const toAddSiteProvisioningStores = siteProvisioningStores.filter(
    provStoreId => !prevSiteProvisioningStores.includes(provStoreId)
  );
  const toRemoveSiteProvisioningStores = prevSiteProvisioningStores.filter(
    provStoreId => !siteProvisioningStores.includes(provStoreId)
  );
  dispatch(saveSitePending(siteId));
  if (toRemoveSiteProvisioningStores?.length) {
    promises.push(
      doRemoveSiteProvisioningStores(siteId, toRemoveSiteProvisioningStores)
        .then(updatedSite => updatedSite)
        .catch(() => {
          // catch error to allow other calls to still complete
          errors.removeStore.isError = true;
          errors.removeStore.message = 'Delete Site Provisioning Stores Failed';
          // return site without provisiong stores removed
          return {
            ...site,
            siteProvisioningStores: prevSiteProvisioningStores,
          };
        })
    );
  }
  if (toAddSiteProvisioningStores?.length) {
    promises.push(
      doSaveSiteProvisioningStores(siteId, toAddSiteProvisioningStores)
        .then((updatedSite: Site) => ({
          ...updatedSite,
          siteProvisioningStores: updatedSite.siteProvisioningStores?.filter(
            s => !toRemoveSiteProvisioningStores?.includes(s)
          ),
        }))
        .catch(e => {
          // catch error to allow other calls to still complete
          errors.addStore.isError = true;
          if (e.response.status === 409) {
            // site tenanacy association not found for link security provisioning stores (id = 2)
            errors.addStore.message = 'Site Tenancy Association Not Found';
          } else {
            errors.addStore.message = 'Save Site Provisioning Stores Failed';
          }
          // return site without new provisiong stores added
          return {
            ...site,
            siteProvisioningStores: prevSiteProvisioningStores?.filter(
              s => !toRemoveSiteProvisioningStores?.includes(s)
            ),
          };
        })
    );
  }
  promises.push(
    doSaveSite(siteId, selectSite(siteId)(getState()))
      .then(updatedSite => updatedSite)
      .catch(_ => {
        errors.saveSite.isError = true;
        errors.saveSite.message = 'Save site failed';
        return { ...site };
      })
  );
  return Promise.all(promises).then(results => {
    if (promises.length > 1) {
      // grab provisioning stores from results of provisioningStore calls
      const siteProvisioningStores = [
        ...new Set(
          results
            .slice(0, results.length - 1)
            .reduce(
              (stores, site) => [
                ...stores,
                ...(site.siteProvisioningStores ?? []),
              ],
              []
            )
        ),
      ];
      const site = {
        ...results[results.length - 1],
        siteProvisioningStores,
        prevSiteProvisioningStores: siteProvisioningStores,
      };
      if (
        errors.saveSite.isError &&
        errors.removeStore.isError &&
        errors.addStore.isError
      ) {
        dispatch(saveSiteFailed(siteId, errors.saveSite.message));
        toast(errors.saveSite.message, { type: 'error', theme: 'colored' });
      } else if (
        errors.saveSite.isError ||
        errors.removeStore.isError ||
        errors.addStore.isError
      ) {
        const error = `${errors.saveSite.message} ${errors.addStore.message} ${errors.removeStore.message}`;
        dispatch(saveSiteFulfilled(siteId, site, error));
        toast(error, {
          type: errors.saveSite.isError ? 'error' : 'warning',
          theme: 'colored',
        });
      } else {
        dispatch(saveSiteFulfilled(siteId, site, ''));
        toast('Site saved', { type: 'success', theme: 'colored' });
        return results[0];
      }
    } else {
      if (!errors.saveSite.isError) {
        dispatch(saveSiteFulfilled(siteId, results[0]));
        toast('Site saved', { type: 'success', theme: 'colored' });
        return results[0];
      } else {
        dispatch(saveSiteFailed(siteId, errors.saveSite.message));
        toast(errors.saveSite.message, { type: 'error', theme: 'colored' });
      }
    }
  });
};

export const publishSite: AsyncThunkActionCreator<Promise<void>> = (
  siteId: number
) => {
  return dispatch => {
    dispatch(publishSitePending(siteId));
    return doPublishSite(siteId)
      .then(() => {
        dispatch(publishSiteFulfilled(siteId));
        toast('Site published', { type: 'success', theme: 'colored' });
      })
      .catch(e => {
        dispatch(publishSiteFailed(siteId));
        toast(e.message, { type: 'error', theme: 'colored' });
      });
  };
};

export const publishSiteToDemo: AsyncThunkActionCreator<Promise<void>> = (
  siteId: number
) => {
  return dispatch => {
    dispatch(publishSitePending(siteId));
    return doPublishSiteToDemo(siteId)
      .then(() => {
        dispatch(publishSiteFulfilled(siteId));
        toast('Site published to Demo', { type: 'success', theme: 'colored' });
      })
      .catch(e => {
        dispatch(publishSiteFailed(siteId));
        toast(e.message, { type: 'error', theme: 'colored' });
      });
  };
};

export const unPublishSite: AsyncThunkActionCreator<Promise<void>> = (
  siteId: number
) => {
  return dispatch => {
    dispatch(unPublishSitePending(siteId));
    return doUnPublishSite(siteId)
      .then(() => {
        dispatch(unPublishSiteFulfilled(siteId));
        toast('Site Unpublished', { type: 'success', theme: 'colored' });
      })
      .catch(e => {
        dispatch(unPublishSiteFailed(siteId));
        toast(e.message, { type: 'error', theme: 'colored' });
      });
  };
};

export const archiveSite: ThunkActionCreator = (siteId: number) => dispatch => {
  dispatch(archiveSitePending(siteId));
  doArchiveSite(siteId)
    .then(() => {
      dispatch(archiveSiteFulfilled(siteId));
      toast('Site Deleted', { type: 'success', theme: 'colored' });
    })
    .catch(e => {
      dispatch(archiveSiteFailed(siteId));
      toast(e.message, { type: 'error', theme: 'colored' });
    });
};

export const fetchPages: ThunkActionCreator = (siteId: number) => dispatch => {
  dispatch(fetchPagesPending(siteId));
  return doFetchPages(siteId)
    .then(pages => {
      dispatch(fetchPagesFulfilled(siteId, pages));
    })
    .catch(e => {
      dispatch(fetchPagesFailed(siteId));
      toast(e.message, { type: 'error', theme: 'colored' });
    });
};

export const createPage: AsyncThunkActionCreator<Promise<Page>> = (
  siteId: number,
  page: Partial<PageVersion>
) => (dispatch: any): Promise<Page> => {
  dispatch(createPagePending(siteId));
  return doCreatePage(siteId, page)
    .then(page => {
      doFetchPages(siteId).then(pages => {
        dispatch(createPageFulfilled(siteId, page));
        dispatch(closeCreatePageDialog());
        dispatch(savePage(siteId, page.id, page.current));
        dispatch(saveInitialPages(pages));
        toast('Page created', { type: 'success', theme: 'colored' });
      });
      return page;
    })
    .catch(e => {
      dispatch(createPageFailed(siteId));
      toast(e.message, { type: 'error', theme: 'colored' });
      return Promise.reject(e);
    });
};

export const savePage: ThunkActionCreator = (
  siteId: number,
  pageId: number,
  page: PageVersion
) => dispatch => {
  dispatch(savePagePending(siteId, pageId));
  doSavePage(siteId, pageId, page)
    .then(page => {
      dispatch(savePageFulfilled(siteId, pageId, page));
      //disabling until entire pages save endpoint is ready
      // toast('Page saved', { type: 'success', theme: 'colored' });
      doFetchSite(siteId).then(site => {
        dispatch(saveInitialSite(site));
      });
    })
    .catch(e => {
      dispatch(savePageFailed(siteId, pageId));
      toast(e.message, { type: 'error', theme: 'colored' });
    });
};

export const saveAllPage: AsyncThunkActionCreator<Promise<
  Array<PageVersion>
>> = (siteId: number, pages: Array<PageVersion>) => dispatch => {
  dispatch(saveAllPagePending(siteId));
  return doSaveAllPage(siteId, pages)
    .then(pages => {
      dispatch(saveAllPageFulfilled(siteId, pages));

      toast('All Pages saved', { type: 'success', theme: 'colored' });
      return pages;
    })
    .catch(e => {
      dispatch(saveAllPageFailed(siteId));
      toast(e.message, { type: 'error', theme: 'colored' });
      return Promise.reject(e);
    });
};

export const updateCurrentPage: ActionCreator<AnyAction> = (
  siteId: number,
  currentPage: PageVersion
) => {
  return { type: UPDATE_CURRENT_PAGE, siteId, currentPage };
};

export const updatePageDefaultWidget: ActionCreator<AnyAction> = (
  siteId: number,
  pageId: number,
  widgetId: string,
  config: any,
  xDifference: number,
  yDifference: number
) => {
  return {
    type: UPDATE_PAGE_DEFAULT_WIDGET,
    siteId,
    widgetId,
    config,
    xDifference,
    yDifference,
    pageId,
  };
};
/**
 * Remove page from a site. Also cleans up any links to that page from the
 * site's nav structure.
 */
export const removePage: ThunkActionCreator = (
  siteId: number,
  pageId: number
) => (dispatch, getState) => {
  dispatch(removePagePending(siteId, pageId));
  dispatch(saveSitePending(siteId));
  const site = selectSite(siteId)(getState());
  doSaveSite(siteId, {
    ...site,
    header: {
      ...site.header,
      nav: {
        ...site.header.nav,
        items: removePageFromNav(pageId, site.header.nav.items),
      },
    },
  })
    .then(site => {
      dispatch(saveSiteFulfilled(siteId, site));
      doRemovePage(siteId, pageId)
        .then(() => {
          doFetchPages(siteId).then(pages => {
            dispatch(removePageFulfilled(siteId, pageId));
            toast('Page deleted', { type: 'success', theme: 'colored' });
            dispatch(saveSitePages(site, pages));
          });
        })
        .catch(e => {
          dispatch(removePageFailed(siteId, pageId));
          toast(e.message, { type: 'error', theme: 'colored' });
        });
    })
    .catch(e => {
      dispatch(saveSiteFailed(siteId));
      dispatch(removePageFailed(siteId, pageId));
      toast(e.message, { type: 'error', theme: 'colored' });
    });
};

/**
 * Selectors
 */

const selectSlice: Selector<State> = () => state => {
  return state.sites;
};

export const selectSitesMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).meta;
};

/**
 * Internal-only selector that returns complete @type {SiteSlice} objects
 */
const selectSitesInternal: Selector<Array<SiteSlice>> = () => state => {
  return selectSlice()(state).data;
};

const selectBaseSite: Selector<SiteSlice> = () => state => {
  return selectSlice()(state).data[0];
};

export const selectDomain: Selector<string> = () => state => {
  return selectBaseSite()(state).domain;
};
/**
 * Selector to retrieve a @type {Site}
 */
export const selectSites: Selector<Array<Site>> = () => state => {
  const rawSites = selectSitesInternal()(state);
  return rawSites.map(({ pages: _a, meta: _b, ...site }) => site);
};

/**
 * Internal-only selector that returns the complete @type {SiteSlice} object
 */
const selectSiteInternal: Selector1<SiteSlice, number> = (
  siteId: number
) => state => {
  return selectSitesInternal()(state).find(site => siteId === site.id);
};

/**
 * Selector to retrieve a @type {Site}
 */
export const selectSite: Selector1<Site, number> = (
  siteId: number
) => state => {
  const siteSlice = selectSiteInternal(siteId)(state);
  // Strip off SiteSlice-specific properties
  const { pages, meta, ...site } = siteSlice;

  // Ensure theme is an object contains the property font or not if yes set the value to Roboto or do nothing if there is no property found.
  if (typeof site.theme.font === 'string') {
    const myFont: NewFont = {
      size: {
        body: {
          base: {
            value: 11,
          },
        },
      },
      weight: {
        base: {
          value: 'normal',
        },
      },
      family: {
        base: {
          value: 'OptumSans',
        },
      },
    };

    site.theme.font = myFont;
  }

  return site;
};

export const selectCreateSitePending: Selector<StateMeta> = () => state => {
  return selectSlice()(state).createSiteMeta;
};

export const selectNewSite: Selector<number> = () => state => {
  return selectSlice()(state).newSiteId;
};

export const selectSiteMeta: Selector1<StateMeta, number> = (
  siteId: number
) => state => {
  return (
    selectSiteInternal(siteId)(state) && selectSiteInternal(siteId)(state).meta
  );
};

export const selectSitePublishingMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).publishMeta;
};

const selectPagesInternal: Selector1<SiteSlice['pages'], number> = (
  siteId: number
) => state => {
  return (
    selectSiteInternal(siteId)(state) && selectSiteInternal(siteId)(state).pages
  );
};

export const selectPages: Selector1<Array<Page>, number> = (
  siteId: number
) => state => {
  const site = selectSiteInternal(siteId)(state);
  return Object.keys(site.pages).map(key => site.pages[key] as Page);
};

export const selectPage: Selector2<Page, number, number> = (
  siteId: number,
  pageId: number
) => state => {
  return (
    selectPagesInternal(siteId)(state) &&
    selectPagesInternal(siteId)(state)[pageId]
  );
};

export const selectDefaultPage: Selector1<Page, number> = (
  siteId: number
) => state => {
  const site = selectSiteInternal(siteId)(state);
  const pageId = Object.keys(site.pages).find(
    key => site.pages[key].current.slug === 'defaultContentPage'
  );
  return site.pages[pageId];
};

export const selectCurrentPage: Selector2<PageVersion, number, number> = (
  siteId: number,
  pageId: number
) => state => {
  return (
    selectPage(siteId, pageId)(state) &&
    selectPage(siteId, pageId)(state).current
  );
};

export const selectWidget: Selector3<Widget<any>, number, number, string> = (
  siteId: number,
  pageId: number,
  widgetId: string
) => state => {
  return (
    selectCurrentPage(siteId, pageId)(state) &&
    selectCurrentPage(siteId, pageId)(state).content[widgetId]
  );
};

export const disableAccountNameSelection: Selector1<boolean, number> = (
  siteId: number
) => state => {
  const site = selectSite(siteId)(state);
  return site.orgAccount !== null;
};

export const selectPageWithWidgetPresent: Selector2<string, number, string> = (
  siteId: number,
  widget: string
) => state => {
  let slug = '';
  const sitePages = selectPages(siteId)(state);
  for (let i = 0; i < sitePages.length; i++) {
    const childLength = sitePages[i].current.content.root.children.length;
    for (let j = 0; j < childLength; j++) {
      const index = sitePages[i].current.content.root.children[j];
      if (sitePages[i].current.content[index].type === widget) {
        slug = sitePages[i].current.slug;
        break;
      }
    }
  }
  return slug;
};
