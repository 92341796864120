import { connect } from 'react-redux';
import { RootState } from 'shared/state';
import { RouteComponentProps, withRouter } from 'react-router';
import { selectSite, selectPages } from 'shared/state/ducks/sites';
import Preview from './Preview';
import { selectUser } from 'shared/state/misc/oidc';

interface PreviewContainerProps {
  siteId: number;
  basePath: string;
}

const mapStateToProps = (
  state: RootState,
  ownProps: PreviewContainerProps & RouteComponentProps<{}>
) => ({
  site: selectSite(ownProps.siteId)(state),
  pages: selectPages(ownProps.siteId)(state),
  user: selectUser()(state),
});

const mapDispatchToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Preview)
);
