import FormWidget from './FormWidget';
import { Config } from './types';
import FormWidgetControl from './FormWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
} from 'builder/util/constants';

export default {
  type: 'Form',
  displayName: 'Form',
  icon: 'file-invoice',
  initialConfig: {
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    borderData: { thickness: 0, radius: 5 },
    width: 420,
    height: 590,
    xLocation: 20,
    yLocation: 20,
    uniqueId: 'form123',
    lineColor: 'black',
    conditionalEmailEnabled: false,
    emailTo: '',
    subject: '',
    timeFrameUnit: '',
    timeFrameValue: '',
    conditionalEmailConfig: [],
    formName: '',
    formActionType: 'email',
    visible: true,
  },
  components: {
    render: FormWidget,
    control: FormWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
