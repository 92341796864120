import CalendarWidget from './CalendarWidget';
import CalendarWidgetControl from './CalendarWidgetControl';
import { Config } from './types';
import {
  defaultBorderData,
  defaultPaddingData,
  defaultCalendarSizeLocationData,
  defaultBackgroundData,
} from 'builder/util/constants';

export default {
  type: 'CalendarWidget',
  displayName: 'Calendar Widget',
  icon: 'Calendars',
  initialConfig: {
    ...defaultCalendarSizeLocationData,
    ...defaultBackgroundData,
    ...defaultPaddingData,
    ...defaultBorderData,
    uniqueId: '123abc',
    lineColor: 'red',
    calendarAccentColor: 'black',
    fontFamily: 'OptumSans',
    isHorizonWidget: true, // Add the missing property
    visible: true,
  },
  components: {
    render: CalendarWidget,
    control: CalendarWidgetControl, // Add the missing property
  },
  copyable: false,
} as HorizonWidgetDefinition<Config>;
