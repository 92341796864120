import SlideShowWidgetContainer from './SlideShowWidgetContainer';
import { Config } from './types';
import SlideShowWidgetControl from 'shared/widgets/SlideShow/SlideShowWidgetControl';
import {
  defaultSlidesData,
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultPaddingData,
  defaultSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'SlideShow',
  displayName: 'SlideShow',
  icon: 'SlideShow',
  initialConfig: {
    ...defaultSizeLocationData,
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultPaddingData,
    ...defaultBorderData,
    ...defaultSlidesData,
    uniqueId: '123abc',
    lineColor: 'red',
    width: 1200,
    height: 331,
    currentSlideIndex: 0,
    visible: true,
  },
  components: {
    render: SlideShowWidgetContainer,
    control: SlideShowWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
