import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledUploadWidget = styled.div``;

const UploadWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const { visible, isCustomWidget } = props.widgetState.config;

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledUploadWidget
      style={{
        marginTop: '0',
        height: '100%',
        width: '100%',
      }}
    ></StyledUploadWidget>
  );
};
export default UploadWidget;
