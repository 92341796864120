import React from 'react'; // ChangeEvent
import { Panel, Label, Button } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';

import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
// import { StyledTextInput } from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';
import { SizeLocationPanel } from '../Link/LinkWidgetControl';

const MarkdownWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;

  // const handleUrl = (e: ChangeEvent<HTMLInputElement>) => {
  //   onChange({
  //     ...value,
  //     url: e.target.value,
  //   });
  // };

  const setIsEditingMarkdown = () => {
    onChange({
      ...value,
      // @ts-ignore
      height: 'auto',
      isEditingMarkdown: !value.isEditingMarkdown,
    });
  };

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      // @ts-ignore
      height: 'auto',
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  return (
    <>
      <Panel.Group>
        {/*<StyledPanel>*/}
        {/*  <Panel*/}
        {/*    title={*/}
        {/*      <Label style={StyledPanelTitle}>Markdown Location URL</Label>*/}
        {/*    }*/}
        {/*    icon={Arrow}*/}
        {/*    data-test-id="general-panel"*/}
        {/*  >*/}
        {/*    <StyledTextInput*/}
        {/*      name="webaddress"*/}
        {/*      value={widgetState.config.url}*/}
        {/*      onChange={handleUrl}*/}
        {/*      placeholder="http:// or https://"*/}
        {/*      data-test-id="markdown-url"*/}
        {/*    />*/}
        {/*  </Panel>*/}
        {/*</StyledPanel>*/}
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Edit Contents</Label>}
            icon={Arrow}
            data-test-id="general-panel"
          >
            <Button
              onPress={setIsEditingMarkdown}
              data-test-id="edit-markdown-button"
            >
              Toggle Markdown Toolbar
            </Button>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                // @ts-ignore
                height: 'auto',
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLocation}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default MarkdownWidgetControl;
