import { TabbedPanel } from '@uitk/react';
import { widgetTypes } from 'builder/util/constants';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledDivContainer = styled.div`
  margin: 1px;
`;

const StyledTabbedPanel = styled(TabbedPanel)<{
  hoverFocusColor: string;
  tabsTextColor: string;
  fontFamily: string;
  fontSize: number;
}>`
  & button {
    color: ${p => p.tabsTextColor} !important;
    font-family: ${p => p.fontFamily} !important;
    font-size: ${p => p.fontSize}pt !important;
  }
  & button:after {
    box-shadow: 0 4px 0 0 ${p => p.hoverFocusColor} !important;
  }
  & button:focus,
  & button:hover {
    box-shadow: 0 0 0 3px ${p => p.hoverFocusColor} !important;
  }
  & > div > div + div > div > div {
    display: none;
  }
`;
const TabsWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    tabs,
    currentTabIndex,
    hoverFocusColor,
    tabsTextColor,
    font,
    fontSize,
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
    visible,
    isCustomWidget,
  } = props.widgetState.config;
  function background() {
    if (backgroundData.style === 'Color') {
      return backgroundData?.color;
    }
    if (backgroundData.style === 'Image') {
      return `url('${backgroundImage?.url}')`;
    }
    return '';
  }
  const getFormattedTabs = () => {
    const formatTabs = [];
    tabs.forEach(tab => {
      formatTabs.push({ header: tab.name, content: tab.content });
    });
    return formatTabs;
  };

  const { value, onChange, currentPage } = props;
  const activeWidgetId = props.widgetState.id;

  React.useEffect(() => {
    if (activeWidgetId !== 'root') {
      const tabWidgetChildren = currentPage.content[activeWidgetId].children;
      tabWidgetChildren.forEach(child => {
        const childWidget = document.getElementById(child);
        const isVisible =
          currentPage.content[child].tabIndex === currentTabIndex;
        if (currentPage.content[child].config.visible !== isVisible) {
          props.changeWidgetVisible(
            props.siteId,
            props.currentPage.pageId,
            activeWidgetId,
            child,
            isVisible,
            currentTabIndex
          );
        }
        if (childWidget) {
          // Todo: Is this a bug? If you have a a background image like a gradient as the background of your Tabs and
          //  then you add another widget that has an Image in it, like a Card, that image will never show because it won't have the
          //  correct z-index. There seems to be some z-index issues in general with Tabs.
          if (currentPage.content[child].type !== widgetTypes.Image) {
            if (currentPage.content[child].type === widgetTypes.Card) {
              childWidget.style.zIndex = '1'; // Todo: Test if this needs to be updated
            } else {
              childWidget.style.zIndex = '2';
            }
          }
        }
      });
    }
  }, [currentTabIndex]);

  const handleTabClick = (panelId: number) => {
    onChange(
      {
        ...value,
        currentTabIndex: panelId,
      },
      activeWidgetId,
      false
    );
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  if (tabs.length > 0) {
    return (
      <StyledDivContainer
        style={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderWidth: `${borderData?.thickness}px`,
          borderStyle: 'solid',
          borderColor: `${lineColor}`,
          borderRadius: `${borderData?.radius}px`,
          backgroundClip: 'content-box',
          background: background(),
          height: '100%',
          width: '100%',
        }}
      >
        <StyledTabbedPanel
          hoverFocusColor={hoverFocusColor}
          tabsTextColor={tabsTextColor}
          fontFamily={font}
          fontSize={fontSize}
          panels={getFormattedTabs()}
          onHeaderPress={handleTabClick}
          selectedPanel={currentTabIndex}
          titlesAs="h2"
          data-test-id="tabbed-panel"
        />
      </StyledDivContainer>
    );
  } else {
    return null;
  }
};
export default TabsWidget;
