import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  Label,
  Select,
  TextInput,
} from '@uitk/react';
import styled from 'styled-components';
import { Icon, linkDeveloperTheme as theme } from 'link-ui-react';
import 'react-toastify/dist/ReactToastify.css';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from '../../ColorPicker/ColorPicker';
import { ProfileIconType } from 'types/types';
import { FormGroup, ListItemIcon } from '@material-ui/core';
import {
  FormControlLabel,
  FormControl as MUIFormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SwitchLovely } from 'builder/scenes/SiteBuilder/SwitchLovely';
import { StyledLink } from 'builder/scenes/SiteBuilder/SiteStylesPanel/SiteStylesPanel';
import { StyledTitle } from 'builder/scenes/SiteBuilder/PagesPanel/PagesPanel';
import { StyledAddItemButton } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import { Add } from '@material-ui/icons';
import { ColorButtonWrapper } from '../Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { IconTrash } from '@tabler/icons-react';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';

const StyledCard = styled.div`
  & div[class^='Cardcomponent__Content'] {
    margin-top: -15px;
  }
`;
const labelStyles = {
  color: '#1d1929',
  'font-size': '14px',
  'font-family': 'OptumSans',
  'padding-top': '7px',
  'font-weight': '500',
};
const secondaryLabelStyles = {
  color: '#1d1929',
  'font-size': '14px',
  'font-family': 'OptumSans',
  'font-weight': '500',
};
const ButtonWrapper = styled.div`
  width: 58%;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0 0 0;
`;
const BlockButtonWrapper = styled.div`
  width: 60%;
  margin-left: -0.3rem;
`;
const StyledButton = styled(Button)`
  & span {
    font-family: OptumSans;
  }
`;
const StyledNewPage = styled.div`
  width: 30rem;
  max-width: 30rem;
  max-height: 75vh;
  height: fit-content;
  position: absolute;
  transform: translate(-105%, -50%);
  overflow-y: auto;
  box-shadow: 1px 0 8px 0 rgba(51, 51, 51, 0.25);
  border: 1px solid #dadbdc;
  border-radius: 0.25rem;
`;
const LoginStyleWrapper = styled.div`
  padding-top: 0.75rem;
  display: flex;
  align-items: center;
  width: 100%;
`;
const IconStyleWrapper = styled.div`
  display: flex;
  margin-top: -6px;
  align-items: center;
`;
const HeaderInputWrapper = styled.div`
  padding: 0.25rem 0;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  & #controlled-text-input {
    width: 9.5rem;
    padding: 0.5rem;
    font-family: OptumSans;
    font-size: 14px;
  }
`;
const CardInputWrapper = styled.div`
  padding: 0.5rem 0;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  & #controlled-text-input {
    width: 16rem;
    padding: 0.5rem;
    font-family: OptumSans;
    font-size: 14px;
  }
`;
export const StyledRadio = styled(Radio)`
  & > input[class^='PrivateSwitchBase-input']:checked + span {
    color: #002677;
  }
`;
const CardSectionWrapper = styled.div`
  border-style: solid none solid none;
  border-width: 1px;
  border-color: #f3f3f3;
  padding-top: 0.5rem;
`;
const MuiIconWrapper = styled.div`
  padding: 0.5rem 0 0 1rem;
  & > div {
    flex-direction: row;
  }
  & > div > div {
    flex-direction: row;
  }
  & .MuiListItemIcon-root {
    min-width: 0;
  }
  & > div > div > label > span + span {
    padding-top: 0.5rem;
  }
`;
export const MuiOptionsWrapper = styled.div`
  & > div {
    flex-direction: row;
  }
  & > div > div {
    flex-direction: row;
  }
  & .MuiTypography-root {
    font-family: OptumSans;
    font-size: 14px;
  }
  & .MuiFormControlLabel-root {
    margin-right: 10px;
  }
`;
const StyledFormGroup = styled(FormGroup)`
  padding: 0.5rem 0;
  width: 100%;
  & .MuiTypography-body1 {
    font-family: OptumSans;
  }
  & > label + label > span {
    margin: 0 4px;
  }
  & > label + label > span + span {
    font-size: 14px;
  }
`;
const FormGroupWrapper = styled.div`
  & .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const LoginStyleSelect = styled(Select)`
  font-family: OptumSans;
  width: 13.25rem;
  border-radius: 0.375rem;
  padding-left: 35px;
  .kASQuK {
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 0.5rem;
    font-size: 14px;
  }
`;
const SelectFormControl = styled(FormControl)`
  display: flex;
  padding: 0.5rem 0;
`;

const LoginColorWrapper = styled.div`
  margin-top: 4px;
  margin-left: 10px;
  display: flex;
`;
const ColorWrapper = styled.div`
  padding: 0 1.5rem;
  display: flex;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-top: 0;
  margin-bottom: -0.5rem;
  font-family: OptumSans;
  font-size: 11pt;
  .YMyVK {
    margin: 0.75rem 0;
  }
`;
const StyledForm = styled(FormControl)`
  width: fit-content;
`;
const OptionCard = styled(Card)`
  margin: 1rem 0;
  & > div {
    padding: 0rem 0rem;
  }
  .cXlVZx * {
    padding: 0rem 0rem;
    margin: 0.5rem 1rem 1rem 0rem;
  }
`;
const StyledIcon = styled.div`
  cursor: pointer;
`;

export interface LoginGeneralPanelProps {
  widgetState: any;
  site: Site;
  updateSite: (site: Site) => void;
  saveSite: (site: Site) => void;
  handleSignInTypeChange: (style: string) => void;
  handleSignInOptionChange: (enabled: string) => void;
  handleSignInOptionTitleChange: (inputValue: string) => void;
  handleProfileIconChange: (icon: ProfileIconType) => void;
  handleOptionTitleColor: (color: string) => void;
  handleButtonColor: (color: string) => void;
  handleSignInOptionLinksChange: (links: Array<SignInLink>) => void;
  handleSimulateUserCheck: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const LoginGeneralPanel: React.FC<LoginGeneralPanelProps> = (
  props: LoginGeneralPanelProps
) => {
  const {
    signInOptionEnabled,
    signInOptionTitle,
    signInLinkColor,
    signInOptionLinks,
    buttonColor,
    signInType,
    signInIcon,
    simulateLoggedOffUser,
  } = props.widgetState.config;
  const {
    site,
    updateSite,
    saveSite,
    handleOptionTitleColor,
    handleButtonColor,
    handleSignInOptionChange,
    handleProfileIconChange,
    handleSignInOptionLinksChange,
    handleSignInOptionTitleChange,
    handleSignInTypeChange,
    handleSimulateUserCheck,
  } = props;

  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [additionalSignInOptions, setAdditionalSignInOptions] = useState<
    Array<SignInLink>
  >(signInOptionLinks);

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = (newColor: string) => {
    if (pickerAnchorEl === document.querySelector('#header-text-color')) {
      if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
        toast('Gradient cannot be used for header text color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        handleOptionTitleColor(newColor);
      }
    } else if (pickerAnchorEl === document.querySelector('#button-color')) {
      handleButtonColor(newColor);
    }
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const handleRemoveSignInOption = (index: number) => () => {
    const newOptions = additionalSignInOptions.filter((_, i) => i !== index);
    setAdditionalSignInOptions(newOptions);
  };

  const handleAddNewOption = () => {
    if (!additionalSignInOptions) {
      setAdditionalSignInOptions([
        { label: '', url: '', type: 'link', openInNewTab: false },
      ]);
      return;
    } else {
      setAdditionalSignInOptions([
        ...additionalSignInOptions,
        { label: '', url: '', type: 'link', openInNewTab: false },
      ]);
    }
  };

  const handleNewOptionLabel = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newOptions = additionalSignInOptions.map((option, i) => {
      if (i === index) {
        return { ...option, label: e.target.value };
      }
      return option;
    });
    setAdditionalSignInOptions(newOptions);
  };

  const handleNewOptionURL = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newOptions = additionalSignInOptions.map((option, i) => {
      if (i === index) {
        return { ...option, url: e.target.value };
      }
      return option;
    });
    setAdditionalSignInOptions(newOptions);
  };

  const handleNewTabCheck = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newOptions = additionalSignInOptions.map((option, i) => {
      if (i === index) {
        return { ...option, openInNewTab: e.target.checked };
      }
      return option;
    });
    setAdditionalSignInOptions(newOptions);
  };

  const handleRadioButtonChange = (e: ChangeEvent<HTMLInputElement>, index) => {
    const newOptions = additionalSignInOptions.map((option, i) => {
      if (i === index) {
        return { ...option, type: e.target.value };
      }
      return option;
    });
    setAdditionalSignInOptions(newOptions);
  };

  const handleSaveClick = () => {
    handleSignInOptionLinksChange(additionalSignInOptions);
    setOptionsOpen(false);
  };

  const handleCancelClick = () => {
    setOptionsOpen(false);
  };

  return (
    <>
      <LoginStyleWrapper>
        <StyledForm id={'simulate-user-checkbox-control'}>
          <StyledCheckbox
            id={'simulate-user-checkbox'}
            data-test-id="simulate-user-checkbox"
            checked={simulateLoggedOffUser}
            onChange={handleSimulateUserCheck}
          >
            Simulate logged-out user
          </StyledCheckbox>
        </StyledForm>
      </LoginStyleWrapper>
      <LoginStyleWrapper>
        <Label style={labelStyles}>Style</Label>
        <SelectFormControl
          data-test-id="background-style-dropdown"
          id={'controlled-select'}
        >
          <LoginStyleSelect
            data-test-id="style-dropdown"
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleSignInTypeChange(e.target.value)
            }
            value={signInType}
            name="style"
          >
            <option value="button">Button</option>
            <option value="link">Link</option>
          </LoginStyleSelect>
        </SelectFormControl>
        {signInType === 'button' && (
          <ColorWrapper>
            <ColorButtonWrapper
              data-test-id="button-color"
              id="button-color"
              onClick={handleColorEditClick}
            >
              <ColorButton color={buttonColor} />
            </ColorButtonWrapper>
          </ColorWrapper>
        )}
        <StyledPickerPopover
          data-test-id="login-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            data-test-id="background-color"
            value={signInLinkColor}
            handleColorChange={handleColorChange}
            onChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
            site={site}
          />
        </StyledPickerPopover>
      </LoginStyleWrapper>
      <IconStyleWrapper>
        <Label style={labelStyles}>Profile Menu Icon</Label>
        <MuiIconWrapper>
          <MUIFormControl id={'radio-group-controlled'}>
            <RadioGroup
              data-cy="icon-radio-group"
              name={'radio-group-controlled'}
              value={signInIcon}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleProfileIconChange(e.target.value as ProfileIconType)
              }
            >
              <FormControlLabel
                value={'MoreVertical'}
                control={<StyledRadio disableRipple />}
                label={
                  <ListItemIcon
                    data-test-id="more-vert-icon"
                    className="morevert"
                  >
                    <Icon
                      data-test-id="morevert-icon"
                      icon="MoreVertical"
                      height={18}
                      width={15}
                    ></Icon>
                  </ListItemIcon>
                }
              />
              <FormControlLabel
                value={'ArrowDown'}
                control={<StyledRadio disableRipple />}
                label={
                  <ListItemIcon
                    data-test-id="arrow-down-icon"
                    className="arrowdown"
                  >
                    <Icon
                      data-test-id="arrowdown-icon"
                      icon="ArrowDown"
                      height={18}
                      width={15}
                    ></Icon>
                  </ListItemIcon>
                }
              />
            </RadioGroup>
          </MUIFormControl>
        </MuiIconWrapper>
      </IconStyleWrapper>
      <FormGroupWrapper>
        <StyledFormGroup data-test-id="signin-toggle-form-group">
          <Label style={secondaryLabelStyles}>Multiple Sign-in Options</Label>
          <FormControlLabel
            control={
              <SwitchLovely
                checked={signInOptionEnabled === 'enabled'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSignInOptionChange(
                    e.target.checked ? 'enabled' : 'notEnabled'
                  )
                }
                value={signInOptionEnabled}
                data-test-id="multi-toggle-switch"
              />
            }
            label={signInOptionEnabled === 'enabled' ? 'On' : 'Off'}
          />
        </StyledFormGroup>
      </FormGroupWrapper>
      {signInOptionEnabled === 'enabled' && (
        <>
          <HeaderInputWrapper>
            <Label style={secondaryLabelStyles}>Header Text</Label>
            <FormControl id={'controlled-text-input'}>
              <TextInput
                data-test-id={'header-text-input'}
                value={signInOptionTitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSignInOptionTitleChange(e.target.value)
                }
              />
            </FormControl>
            <LoginColorWrapper>
              <ColorButtonWrapper
                data-test-id="header-text-color"
                id="header-text-color"
                onClick={handleColorEditClick}
              >
                <ColorButton color={signInLinkColor} />
              </ColorButtonWrapper>
            </LoginColorWrapper>
          </HeaderInputWrapper>
          <StyledLink
            style={{ width: 'fit-content', paddingTop: '0.75rem' }}
            data-test-id="edit-options-link"
            onClick={setOptionsOpen}
          >
            Customize multiple sign-ins{' ->'}
          </StyledLink>
          {optionsOpen && (
            <StyledNewPage
              data-test-id="multiple-options-popover"
              onClick={handleInputClick}
            >
              <StyledCard
                onClick={handleInputClick}
                data-test-id="multiple-options-card"
              >
                <Card
                  header={
                    <Label style={StyledTitle}>
                      Configure Multiple Sign-in Options
                    </Label>
                  }
                  data-test-id="options-card"
                >
                  {additionalSignInOptions &&
                    additionalSignInOptions.map((option, index) => (
                      <OptionCard
                        data-test-id={`option-card-${index}`}
                        header={
                          <>
                            <Label data-test-id="option-header">
                              Sign-in option #{index + 1}
                            </Label>
                            <StyledIcon
                              data-test-id="delete-option-icon"
                              title="Delete option"
                              onClick={handleRemoveSignInOption(index)}
                            >
                              <IconTrash size={24} stroke={2} />
                            </StyledIcon>
                          </>
                        }
                        key={index}
                      >
                        <CardSectionWrapper>
                          <CardInputWrapper>
                            <Label style={secondaryLabelStyles}>
                              Sign-in label
                            </Label>
                            <FormControl id={'controlled-text-input'}>
                              <TextInput
                                value={option?.label}
                                onChange={e => handleNewOptionLabel(index, e)}
                              />
                            </FormControl>
                          </CardInputWrapper>
                          <MuiOptionsWrapper>
                            <MUIFormControl
                              id={`radio-group-controlled-${index}`}
                            >
                              <RadioGroup
                                name={`radio-group-controlled-${index}`}
                                value={option.type}
                                onChange={e =>
                                  handleRadioButtonChange(e, index)
                                }
                              >
                                <FormControlLabel
                                  value={'optum-id'}
                                  control={<StyledRadio disableRipple />}
                                  label={'OneHealthcare ID'}
                                />
                                <FormControlLabel
                                  value={'uhg-ms-id'}
                                  control={<StyledRadio disableRipple />}
                                  label={'MSID'}
                                />
                                <FormControlLabel
                                  value={'portal-login'}
                                  control={<StyledRadio disableRipple />}
                                  label={'Both'}
                                />
                                <FormControlLabel
                                  value={'custom'}
                                  control={<StyledRadio disableRipple />}
                                  label={'Custom URL'}
                                />
                              </RadioGroup>
                            </MUIFormControl>
                          </MuiOptionsWrapper>
                          {option.type === 'custom' && (
                            <CardInputWrapper>
                              <Label style={secondaryLabelStyles}>
                                Custom URL
                              </Label>
                              <FormControl id={'controlled-text-input'}>
                                <TextInput
                                  value={option?.url}
                                  onChange={e => handleNewOptionURL(index, e)}
                                />
                              </FormControl>
                            </CardInputWrapper>
                          )}
                        </CardSectionWrapper>
                        <StyledForm id={`webaddress-checkbox-control-${index}`}>
                          <StyledCheckbox
                            id={`webaddress-checkbox-${index}`}
                            data-test-id={`webaddress-checkbox-${index}`}
                            checked={option?.openInNewTab}
                            onChange={e => handleNewTabCheck(index, e)}
                          >
                            Open in new tab
                          </StyledCheckbox>
                        </StyledForm>
                      </OptionCard>
                    ))}
                  <BlockButtonWrapper>
                    <StyledAddItemButton
                      variant="secondary"
                      onClick={handleAddNewOption}
                      icon={<Add />}
                      data-test-id="add-item-button"
                    >
                      Add a sign-in option
                    </StyledAddItemButton>
                  </BlockButtonWrapper>
                  <ButtonWrapper>
                    <StyledButton
                      onPress={handleSaveClick}
                      data-test-id="apply-changes-button"
                    >
                      Apply changes
                    </StyledButton>
                    <StyledButton
                      variant="tertiary"
                      onPress={handleCancelClick}
                      className={'mt-base'}
                      data-test-id="cancel-button"
                    >
                      Cancel
                    </StyledButton>
                  </ButtonWrapper>
                </Card>
              </StyledCard>
            </StyledNewPage>
          )}
        </>
      )}
    </>
  );
};
