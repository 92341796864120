import { Dispatch } from 'shared/state';
import { connect } from 'react-redux';
import SlideShowWidget from './SlideShowWidget';
import { changeWidgetVisible } from 'shared/state/ducks/sites';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeWidgetVisible: (
    siteId: number,
    pageId: number,
    parentWidgetId: string,
    widgetId: string,
    visible: boolean,
    currentTabIndex?: number | string
  ) => {
    dispatch(
      changeWidgetVisible(
        siteId,
        pageId,
        parentWidgetId,
        widgetId,
        visible,
        currentTabIndex
      )
    );
  },
});

export default connect(null, mapDispatchToProps)(SlideShowWidget);
