import { widgetSchema } from './schema';
import { BaseConfig } from './shared/types';
import TextWidget from './Text';
import DividerWidget from '../widgets/Divider';
import VideoUpload from './Video';
import ImageWidget from './Image';
import HtmlWidget from './Html';
import ButtonWidget from './Button';
import SectionWidget from './Section';
import FileUploaderWidget from './Form/FileUploader';
import CardWidget from './Card';
import AccordionWidget from './AccordionWidget';
import TextInputWidget from './Form/TextInput';
import RadioButton from './Form/RadioButton';
import SlideShowWidget from './SlideShow';
import CheckboxButton from './Form/CheckboxButton';
import BannerWidget from './Banner';
import TabsWidget from './Tabs';
import Dropdown from './Form/Dropdown';
import NavWidget from './Navigation';
import LinkWidget from './Link';
import FormBlock from './Form/FormBlock';
import MyApps from './MyApps';
import LoginButton from './LoginButton';
import UploadWidget from './UploadWidget';
import Markdown from './Markdown';
import CalendarWidget from './CalendarWidget';

const widgets = [
  Markdown,
  TextWidget,
  DividerWidget,
  ImageWidget,
  VideoUpload,
  HtmlWidget,
  ButtonWidget,
  SectionWidget,
  FileUploaderWidget,
  CardWidget,
  TextInputWidget,
  BannerWidget,
  RadioButton,
  TabsWidget,
  SlideShowWidget,
  Dropdown,
  CheckboxButton,
  AccordionWidget,
  FormBlock,
  NavWidget,
  LinkWidget,
  MyApps,
  LoginButton,
  UploadWidget,
  CalendarWidget,
];
const getWidgets = (): Array<HorizonWidgetDefinition<any>> => {
  return widgets.map(widget => {
    return {
      ...widget,
      initialConfig: {
        ...InitialBaseConfig,
        ...widget.initialConfig,
      },
    };
  });
};

const getWidget = (
  type: string,
  newHorizon: boolean = false
): HorizonWidgetDefinition<any> => {
  // Add newly created widget types to this list to avoid app crash temporarily
  const newWidgetTypes = [
    MyApps.type,
    TextWidget.type,
    DividerWidget.type,
    ImageWidget.type,
    VideoUpload.type,
    HtmlWidget.type,
    ButtonWidget.type,
    SectionWidget.type,
    FileUploaderWidget.type,
    CardWidget.type,
    TextInputWidget.type,
    BannerWidget.type,
    RadioButton.type,
    TabsWidget.type,
    SlideShowWidget.type,
    Dropdown.type,
    CheckboxButton.type,
    AccordionWidget.type,
    FormBlock.type,
    NavWidget.type,
    LinkWidget.type,
    LoginButton.type,
    UploadWidget.type,
    Markdown.type,
    CalendarWidget.type,
  ];
  let widgetType = type;
  if (newHorizon) {
    widgetType = newWidgetTypes.includes(type) ? type : 'Text';
  }
  return getWidgets().find(widget => widgetType === widget.type);
};

//DFS for finding form widgets within a tree page structure
const searchWidgetsByType = (
  content: PageContent,
  id: string,
  result: Array<Widget<any>>,
  type: string
): Array<Widget<any>> => {
  if (content[id].type === type) {
    result.push(content[id]);
  }
  if (!content[id].children.length) {
    return;
  }
  const newIds: Array<String> = content[id].children;
  newIds.forEach((item: string) => {
    searchWidgetsByType(content, item, result, type);
  });
  return result;
};

const InitialBaseConfig: BaseConfig = {
  spacing: {
    top: '',
    right: '',
    bottom: '',
    left: '',
  },
  isHorizonWidget: true,
  isCustomWidget: false,
  customWidgetName: '',
  customWidgetDescription: '',
};

export { getWidgets, getWidget, searchWidgetsByType, widgetSchema };
